import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Container, Card, TitleContainer, Title, Title2, Button, Breadcrumb } from "../../styles/styledComponents";
import { getCompany } from './services/company.services'; // Servicio que obtiene los datos de la empresa
import { ErrorAlert } from "../../components/ErrorAlert";

export function Empresa() {
  const navigate = useNavigate(); // Inicializar el hook para navegar
  const [company, setCompany] = useState(null); // Estado para almacenar la empresa
  const [loading, setLoading] = useState(true); // Estado de carga
  const [error, setError] = useState(null); // Estado para manejar errores

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const response = await getCompany(); // Obtener los datos de la empresa
        setCompany(response.data); // Guardar la información de la empresa en el estado
        console.log("informacion",response)
      } catch (err) {
        setError("Error al obtener la empresa");
      } finally {
        setLoading(false); // Detener el estado de carga
      }
    };
    
    fetchCompany(); // Llamar a la función cuando se monte el componente
  }, []);

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Container>

      {error && <ErrorAlert error={error} />}

      {/* <BackButton onClick={() => navigate(-1)}>←</BackButton> */}
      <TitleContainer>
        <Title>Empresa</Title>
          <Breadcrumb items={[
            { name: "Inicio", link: "/tienda" },
            { name: "Empresa", link: "/empresa" }
          ]} />
      </TitleContainer>
      <Card>
        {!company ? (
          <>          
            <Button onClick={() => navigate("/crear-empresa")}>Crear Empresa</Button> {/* Redirigir al formulario */}
            <InfoContainer><div>No hay empresas registradas</div></InfoContainer>
          </>
        ) : (
          <InfoContainer>
            <ButtonContainer>
              <Title2>Información de empresa</Title2>
              <Button onClick={() =>
                            navigate("/editar-empresa", {
                              state: {
                                companyId: company.companyId,
                              },
                            })
                          }>Modificar información</Button> {/* Redirigir al formulario */}
            </ButtonContainer>        

            <InfoContainerItem>
              <InfoItem><strong>Razón social: </strong>{company.businessName}</InfoItem>
              <InfoItem><strong>Dirección: </strong>{company.address}</InfoItem>
              <InfoItem><strong>Correo: </strong>{company.email}</InfoItem>
              <InfoItem><strong>Rut: </strong>{company.identificationNumber}</InfoItem>
            </InfoContainerItem>

          </InfoContainer>
        )}
      </Card>
    </Container>
  );
}

// Estilos        
const ButtonContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const BackButton = styled.button`
  align-self: flex-start;
  background: none;
  border: none;  
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 20px;
`;

const InfoContainer = styled.div``;

const InfoTitle = styled.h2`
  margin: 0;
`;

const InfoContainerItem = styled.div`
  margin-top: 20px; /* Aumenta el margen superior para bajar el cuadro */
`;

const InfoItem = styled.p`
  margin: 5px 0;
  font-size: 16px;
  line-height: 1.5;
`;
