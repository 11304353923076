import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  Container,
  CardForm,
  TitleForm,
  InfoForm,
} from "../../styles/styledComponents";

export function CrearTiendaEtapa3() {
  // Cargar los datos desde localStorage si existen
  const [descrempresa, setDescrempresa] = useState(
    localStorage.getItem("descrempresa") || ""
  );
  const [descrquiensomos, setDescrquiensomos] = useState(
    localStorage.getItem("descrquiensomos") || ""
  );
  const [desctermycond, setDesctermycond] = useState(
    localStorage.getItem("desctermycond") || ""
  );

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Guardar la información en localStorage
    localStorage.setItem("descrempresa", descrempresa);
    localStorage.setItem("descrquiensomos", descrquiensomos);
    localStorage.setItem("desctermycond", desctermycond);

    navigate("/crear-tiendaet4"); // Redirigir a la cuarta etapa
  };

  return (
    <Container>
      <CardForm>
        <TitleForm>CREAR TIENDA</TitleForm>
        <InfoForm>Ingrese datos de empresa</InfoForm>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
          <Label>Descripción Empresa</Label>
          <TextArea
            value={descrempresa}
            onChange={(e) => setDescrempresa(e.target.value)}
          />
          <FormGroup>
          </FormGroup>
          <Label>Misión</Label>
          <TextArea
            value={descrquiensomos}
            onChange={(e) => setDescrquiensomos(e.target.value)}
          />
          <FormGroup>
          </FormGroup>
          <Label>Visión</Label>
          <TextArea
            value={desctermycond}
            onChange={(e) => setDesctermycond(e.target.value)}
          />
          </FormGroup>
          <Button type="submit">Continuar</Button>
        </Form>
      </CardForm>
    </Container>
  );
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
`;

const Title = styled.h1`
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const FormGroup = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
`;

const Label = styled.label`
  margin-top: 10px;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;
const TextArea = styled.textarea`
  border: 1px solid #ddd;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  width: 100%;
  padding: 8px;
  height: 100px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #000;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #333;
  }
`;
