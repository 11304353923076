import axios from 'axios';

const API_URL = process.env.REACT_APP_COMPANYMANAGEMENT_API_URL; 
// https://localhost:7146/User/51236d0f-2386-4d2d-9d3e-93f8a1c8b17b

export const createUser = async (userData, companyId) => {
    try {
      const response = await axios.post(`${API_URL}/User`, userData, {
        headers: {
          'companyId': companyId, // Incluye el companyId en los headers
          'Content-Type': 'application/json', // Asegura que el content type sea correcto
        },
      }); 
      console.log("informacion usuario:",response)

    
      return response.data;
    
    } catch (error) {
      console.error('Error al crear el usuario:', error);
      throw error;
    }
  };
  
  export const updateUser = async (userId, formData) => {
    try {
      const response = await axios.put(
        `${API_URL}/User/${userId}`,
        formData, // Enviar directamente formData sin anidarlo
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el usuario:", error);
      throw error;
    }
  };

export const getUsers = async (userId) => {
          
        // Verificar que el userId exista en localStorage antes de realizar la solicitud
        if (!userId) {
          throw new Error('No se encontró el ID de la empresa en localStorage');
        }
  
        
    return await axios.get(`${API_URL}/User/${userId}`);
};

export const deleteUser = (userId, data) => {
  return axios.delete(`${API_URL}/User/${userId}`, {
    data: data, // Envía el cuerpo con la razón de rechazo
  });
};

export const getAllusers = async () => {


    const response = await axios.post(`${API_URL}/User/GetAll`, {
      data: {
        reason: "Producto eliminado por el usuario" // Ajusta el motivo según lo que necesites
      }
    });
    console.log("Response data service:", response);

    return response.data;

};
