import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getInfouser } from './services/login.services'; // Asegúrate de importar el servicio
import UserCreate from '../usuarios/UserCreate'; // Importa tu componente UserCreate
import logo from '../../assets/logo.png';
import { AiOutlineClose, AiOutlineWarning } from "react-icons/ai";
import { ErrorAlert } from "../../components/ErrorAlert";

export const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // Estado para carga
  const [showCreateAccount, setShowCreateAccount] = useState(false); // Controla si se muestra el formulario de crear cuenta
  const [showModal, setShowModal] = useState(false); // Estado para mostrar el modal
  const [modalMessage, setModalMessage] = useState(""); // Mensaje del modal
  const [error, setError] = useState(null); // Estado para almacenar el error si ocurre

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Inicia el estado de carga
    
    const userData = {
      email: email,
      password: password
    };

    try {
      const response = await getInfouser(userData);
      console.log("response", response);
      console.log("id", response.user.id);
      console.log("idcompany", response.user.companyId);
      console.log("isEnable", response.user.isEnabled);
      console.log("AuthToken", response.authToken);

      const isEnabled = response.user.isEnabled;
      if (isEnabled) {
        localStorage.setItem("userId", response.user.id);
        localStorage.setItem("companyId", response.user.companyId);
        localStorage.setItem("authToken", response.authToken);
        navigate("/tienda"); // Redirigir a la página principal tras un login exitoso
      } else {
        setModalMessage("Falta autorización por parte del administrador.");
        setShowModal(true); // Mostrar el modal si no está habilitado
      }
    } catch (error) {
      setError(error); // Almacena el error en el estado para que el componente ErrorAlert lo procese

      console.error("Error al iniciar sesión:", error);
    } finally {
      setLoading(false); // Finaliza el estado de carga
    }
  };

  const handleCreateAccount = () => {
    setShowCreateAccount(true); // Muestra el componente de crear cuenta
  };

  const closeModal = () => {
    setShowModal(false); // Cerrar el modal
  };

  return (
    <LoginContainer>
            {error && <ErrorAlert error={error} />}

      {showCreateAccount ? (
        <UserCreate /> // Renderiza el componente de crear cuenta si el usuario hace clic en "Crear cuenta"
      ) : (
        <Form onSubmit={handleSubmit}>
          <div className="imgcontent">
            <img src={logo} />
          </div>
          <Title>Inicia sesión en su cuenta para continuar</Title>
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit" disabled={loading}>
            {loading ? "Cargando..." : "Ingresar"}
          </Button>
          <TextLink>¿No tiene cuenta registrada? <Link onClick={handleCreateAccount}>Crea una</Link></TextLink>
        </Form>
      )}

      {showModal && (
        <ModalOverlay onClick={closeModal}>
          <ModalContent className={showModal ? "visible" : "hidden"} onClick={(e) => e.stopPropagation()}>
            <div><Icon><AiOutlineWarning /></Icon> {modalMessage}</div>
            <ButtonClose onClick={closeModal}> <Icon><AiOutlineClose /></Icon>
            </ButtonClose>
          </ModalContent>
        </ModalOverlay>
      )}
    </LoginContainer>
  );
};

const LoginContainer = styled.div`
  height: 50vh;
  width: 60vh;
  @media (max-width: 480px) {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  align-items: start;
  font-family: 'Roboto', sans-serif;
  justify-content: center;
  .imgcontent {
    display: flex;
    justify-content: center;
    img {
        height: 100%;
      }
    height: 40px;
    margin-top: 40px;
  }
`;

const Form = styled.form`
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  margin: auto;
`;

const Title = styled.h2`
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 16px;
`;

const Input = styled.input`
  padding: 10px;
  background-color: #f8f8f8;
  border: 1px solid #fff;
  border-radius: 3px;
  margin: 0;
  font-size: 14px;
  &:focus {
    border: 1px solid #ccc;
    background-color: transparent;
    outline: none;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #000;
  font-family: 'Roboto', sans-serif;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    background-color: #333;
  }
`;
const ButtonClose = styled.button`
  background-color: transparent;
  font-family: 'Roboto', sans-serif;
  border: none;
  cursor: pointer;
  border-radius: 0;
  height: 16px;
  width: 16px;
  margin-left: 40px;
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    color: #333;
  }
`;
const Icon = styled.svg`
  height: 14px;
  width: 14px;
  margin-right: 10px;
  color: #663c00;

`;
const TextLink = styled.div`
  color: #000;
  text-align: center;
  font-size: 14px;
`;
const Link = styled.a`
  color: #000;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  &:hover {
    color: #f5213d;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: start;
`;

const ModalContent = styled.div`
  margin-top: 20vh;
  background-color: #fff4e5;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #663c00;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s ease, transform 0.3s ease;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  &.hidden {
    opacity: 0;
    transform: translateY(-20px);
  }

  p {
    margin: 0 0 20px;
  }
`;

export default LoginPage;
