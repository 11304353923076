import React from "react";
import axios from "axios";

export const ErrorAlert = ({ error }) => {
  const handleError = () => {
    // Extraer información del error
    if (axios.isAxiosError(error) && error.response && typeof error.response.data === 'object') {
      const { errorCode, errorDescription } = error.response.data;

      // Mostrar la alerta con el código de error y la descripción
      if (errorCode && errorDescription) {
        alert(`Error: ${errorDescription}\nCódigo de error: ${errorCode}`);
      } else {
        alert("Ocurrió un error no contemplado");
        console.log(error)

      }
    } else {
    }
  };

  // Mostrar la alerta automáticamente cuando se carga el componente
  React.useEffect(() => {
    if (error) {
      handleError();
    }
  }, [error]);

  return null; // No se necesita renderizar nada, solo mostrar la alerta
};

export default ErrorAlert;
