import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LoginPage from './LoginPage'; // Importa el componente LoginPage
import Main from './MainContent'; // Importa el componente LoginPage
import logo from '../../assets/logo.png';
import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Footer } from "../../components/Footer";

// Estilos
const PageContainer = styled.div`
    display: flex;
    width: 100vw;
    flex-direction: column;
    min-height: 100vh; // Para cubrir toda la pantalla
    font-family: 'Roboto', sans-serif;
    right: 0;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    background-color: #000000c0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1002;
    border-radius: 0 0 3px 3px;
    margin: 0 auto;
    left: 0;
    right: 0px;
`;
const Nav = styled.nav`
    gap: 20px; // Espacio entre los items
    padding: 10px;
`;
const Logo = styled.div`
    padding: 10px;
    .imgcontent {
        display: flex;
        justify-content: center;
        img {
            height: 100%;
        } 
        height: 50px;
        margin: -10px
    }
`;

const NavItem = styled.a`
    color: white; 
    text-decoration: none;
    text-transform: uppercase;
    padding: 10px 20px;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    &:hover {
        color: #e95b5b;
    }
`;

const Content = styled.div`
    flex: 1;
    z-index: 1000;
`;




const IntranetButton = styled.button`
    background-color: transparent;
    font-family: 'Roboto', sans-serif;
    color: white;
    padding: 10px 20px;
    border: 1px solid #ffffff6f;
    border-radius: 3px;
    font-size: 12px;
    cursor: pointer;
    text-transform: uppercase;
    transition: background-color 0.3s, color 0.3s;
    height: min-content;
    margin-right: 50px;
    &:hover {
        background-color: #f5213d;
        border: 1px solid #f5213d;
        color: #000;
    }
`;

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1005;
`;

const ModalContent = styled.div`
    background-color: transparent;
    padding-bottom: 60px;
    padding-right: 60px;
    z-index: 1007;
    width: 60vh;
    /* height: 40vh; */
    @media (max-width: 480px) {
        width: 100%;
    }
`;
const Form = styled.form`
    display: flex;
    justify-content: center;
    margin-top: 40px;
`;
const Input = styled.input`
  padding: 10px;
  background-color: #f8f8f8;
  border: 0;
  border-radius: 3px 0 0 3px;  
  margin: 0;
  font-size: 14px;
  width: 30%;
  &:focus {
    background-color: transparent;
    outline: none;
  }
`;
const SearchButton = styled.button`
 border: 0;
 border-radius: 0;
 background-color: #5b7ce9;
 padding: 0 10px;
 color: #fff;
 border-radius: 0 3px 3px 0;
 cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    &:hover {
        background-color: #4f72e2;
    }
`;
// Componente principal
export const HomePage = () => {
    const [showLogin, setShowLogin] = useState(false);

    const toggleLogin = () => {
        setShowLogin(!showLogin);
    };

    return (
        <PageContainer>
            <Header>
                <Logo>
                    <div className="imgcontent">
                        <img src={logo} />
                    </div>
                </Logo>
                <Nav>
                
                    {/* <NavItem>Diseño</NavItem>
                    <NavItem>Publicidad</NavItem>
                    <NavItem>Marketing</NavItem> */}
                </Nav>
                <IntranetButton onClick={toggleLogin}>Iniciar sesión</IntranetButton>
            </Header>
            <Content>
                <Main />
            </Content>
            <Footer />
            {showLogin && (
                <ModalOverlay onClick={toggleLogin}>
                    <ModalContent onClick={(e) => e.stopPropagation()}>
                        <LoginPage closeModal={toggleLogin} />
                    </ModalContent>
                </ModalOverlay>
            )}
        </PageContainer>
    );
};

export default HomePage;
