// ShippingInfoImage.js
import React, { useRef } from "react";
import styled from "styled-components";
import html2canvas from "html2canvas";

const ShippingInfoImage = ({ firstName, lastName, address, phone, rut, order }) => {
  const shippingInfoRef = useRef(null);

  const handleGenerateImage = () => {
    if (shippingInfoRef.current) {
      html2canvas(shippingInfoRef.current).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = `Envio_Pedido_${firstName}_${lastName}_${order}.png`;
        link.click();
      });
    }
  };

  return (
    <div>
      <ShippingInfoContainer ref={shippingInfoRef}>
        <InfoRow><InfoLabel>Nombre:</InfoLabel><InfoValue>{firstName} {lastName}</InfoValue></InfoRow>
        <InfoRow><InfoLabel>Rut:</InfoLabel><InfoValue>{rut}</InfoValue></InfoRow>
        <InfoRow><InfoLabel>Dirección:</InfoLabel><InfoValue>{address}</InfoValue></InfoRow>
        <InfoRow><InfoLabel>Fono:</InfoLabel><InfoValue>{phone}</InfoValue></InfoRow>
      </ShippingInfoContainer>
      
      {/* Botón fuera del contenedor de imagen */}
      <GenerateButton onClick={handleGenerateImage}>Generar Imagen</GenerateButton>
    </div>
  );
};
const ShippingInfoContainer = styled.div`
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  max-width: 400px;
  margin-top: 20px;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const InfoLabel = styled.span`
  font-weight: bold;
`;

const InfoValue = styled.span``;

const GenerateButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

export default ShippingInfoImage;
