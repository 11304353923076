import styled from "styled-components";
import { useState } from "react";
import { createCompany } from './services/company.services'; // Asegúrate de importar el servicio
import { useNavigate } from "react-router-dom";
import { ErrorAlert } from "../../components/ErrorAlert";

export function CompanyCreate() {
  const navigate = useNavigate();
  const [error, setError] = useState(null); // Estado para almacenar el error si ocurre

  const [companyData, setCompanyData] = useState({
    identificationType: "RUT", // Tipo de identificación (puedes ajustarlo si es dinámico)
    identificationNumber: "",
    businessName: "",
    address: "",
    email: "",
    phone: ""
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyData({
      ...companyData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await createCompany(companyData);
      console.log("Compañía creada:", response.data);
      navigate("/empresa");
    } catch (error) {
      setError(error); // Almacena el error en el estado para que el componente ErrorAlert lo procese
      console.error("Error creando la compañía:", error);
    }
  };

  return (
    <Container>
      {/* Muestra la alerta si hay un error */}
      {error && <ErrorAlert error={error} />}
      
      <BackButton>←</BackButton>
      <Title>CREAR COMPAÑÍA</Title>
      
      <FormContainer onSubmit={handleSubmit}>
        <InputContainer>
          <Label>Nombre de la Empresa (Razón Social):</Label>
          <Input 
            type="text" 
            name="businessName" 
            value={companyData.businessName} 
            onChange={handleInputChange} 
            required 
          />
        </InputContainer>

        <InputContainer>
          <Label>Dirección:</Label>
          <Input 
            type="text" 
            name="address" 
            value={companyData.address} 
            onChange={handleInputChange} 
            required 
          />
        </InputContainer>

        <InputContainer>
          <Label>Correo:</Label>
          <Input 
            type="email" 
            name="email" 
            value={companyData.email} 
            onChange={handleInputChange} 
            required 
          />
        </InputContainer>

        <InputContainer>
          <Label>RUT (Número de Identificación):</Label>
          <Input 
            type="text" 
            name="identificationNumber" 
            value={companyData.identificationNumber} 
            onChange={handleInputChange} 
            required 
          />
        </InputContainer>

        <InputContainer>
          <Label>Teléfono:</Label>
          <Input 
            type="text" 
            name="phone" 
            value={companyData.phone} 
            onChange={handleInputChange} 
            required 
          />
        </InputContainer>

        <Button type="submit">Crear Compañía</Button>
      </FormContainer>
    </Container>
  );
}

// Estilos
const Container = styled.div`
  height: 100vh; 
  display: flex; 
  flex-direction: column;
  align-items: center;
  padding: 40px;
`;

const BackButton = styled.button`
  align-self: flex-start;
  background: none;
  border: none;  
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  margin-bottom: 20px;
`;

const FormContainer = styled.form`
  margin-top: 50px;
  border: 3px solid #ddd;
  border-radius: 5px;
  padding: 30px;
  width: 850px;
  background-color: #f9f9f9;
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 15px;
  border-radius: 5px;
  border: 1px solid #ddd;
`;

const Button = styled.button`
  background-color: #add8e6;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 15px;
  margin-top: 20px;

  &:hover {
    background-color: #87cefa;
  }
`;
