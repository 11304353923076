import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { createProduct } from "./services/products.services"; // Import the createProduct service
import { useLocation } from "react-router-dom";
import {
  Container,
  Card,
  TitleContainer,
  Title,
  Title2,
  Row,
  Column50,
  Form,
  FormRow,
  Label,
  Input,
  TextArea,
  Button,
  Breadcrumb
} from "../../styles/styledComponents";
import { ErrorAlert } from "../../components/ErrorAlert";

export const ProductsCreate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { storeId } = location.state || {}; // Extraer storeId del estado de navegación
  const [error, setError] = useState(null); // Estado para almacenar el error si ocurre

  const [formData, setFormData] = useState({
    name: "",
    quantity: 1,
    description: "",
    prices: {
      normalPrice: "",
      merchant: {
        percentage: 0,
        miniumQuantity: 0
      },
      wholesaler: {
        percentage: 0,
        miniumQuantity: 0
      },
      distributors: {
        percentage: 0,
        miniumQuantity: 0
      }
    },
    offer: {
      enable: false,
      percentage: 0,
    },
    addCategory: {
      mainCategory: "",
      subCategory: "",
    },
    images: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Validación para asegurarse de que los valores de porcentaje y cantidad mínima estén en el rango adecuado
    if ((name.includes("Percentage") || name.includes("MiniumQuantity")) && (value < 0 || value > 90)) {
      return; // Evita actualizar el estado si el valor está fuera de rango
    }

    // Handle nested fields for prices, offer, category, images, and wholesale
    if (name === "grossPrice" || name === "normalPrice") {
      setFormData((prev) => ({
        ...prev,
        prices: {
          ...prev.prices,
          [name]: value,
        },
      }));
    } else if (name === "merchantPercentage" || name === "merchantMiniumQuantity") {
      setFormData((prev) => ({
        ...prev,
        prices: {
          ...prev.prices,
          merchant: {
            ...prev.prices.merchant,
            [name === "merchantPercentage" ? "percentage" : "miniumQuantity"]: value,
          },
        },
      }));
    } else if (name === "wholesalerPercentage" || name === "wholesalerMiniumQuantity") {
      setFormData((prev) => ({
        ...prev,
        prices: {
          ...prev.prices,
          wholesaler: {
            ...prev.prices.wholesaler,
            [name === "wholesalerPercentage" ? "percentage" : "miniumQuantity"]: value,
          },
        },
      }));
    } else if (name === "distributorsPercentage" || name === "distributorsMiniumQuantity") {
      setFormData((prev) => ({
        ...prev,
        prices: {
          ...prev.prices,
          distributors: {
            ...prev.prices.distributors,
            [name === "distributorsPercentage" ? "percentage" : "miniumQuantity"]: value,
          },
        },
      }));
    } else if (name === "enable" || name === "percentage") {
      setFormData((prev) => ({
        ...prev,
        offer: {
          ...prev.offer,
          [name]: name === "enable" ? e.target.checked : value,
        },
      }));
    } else if (name === "mainCategory" || name === "subCategory") {
      setFormData((prev) => ({
        ...prev,
        addCategory: {
          ...prev.addCategory,
          [name]: value,
        },
      }));
    } else if (name.startsWith("image")) {
      const index = parseInt(name.slice(5)) - 1;
      setFormData((prev) => {
        const newImages = [...prev.images];
        newImages[index] = value;
        return { ...prev, images: newImages };
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await createProduct(formData, storeId); // Create a new product
      if (response.errorCode) {
        console.error("Error creating product:", response);
        return;
      }
      navigate("/tienda-productos", { state: { storeId: storeId } });
    } catch (error) {
      setError(error); // Almacena el error en el estado para que el componente ErrorAlert lo procese
      console.error("Error creating product:", error);
    }
  };

  return (
    <Container>
      {error && <ErrorAlert error={error} />}
  
      <TitleContainer>
        <Title>Productos</Title>
        <Breadcrumb
          items={[{ name: "Inicio", link: "/tienda" }, { name: "Tiendas", link: "/tienda" }, { name: "Crear producto", link: "/" }]}
        />
      </TitleContainer>
  
      <Card>
        <Title2>Crear Producto</Title2>
  
        <Form onSubmit={handleSubmit} style={{ marginRight: "-20px" }}>
          {/* Información del Producto */}
          <h3>Información del Producto</h3>
          <Row>
            <Column50>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Nombre del Producto</Label>
                <Input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </FormRow>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Cantidad</Label>
                <Input
                  type="number"
                  name="quantity"
                  value={formData.quantity}
                  onChange={handleInputChange}
                  required
                />
              </FormRow>
            </Column50>
  
            {/* Descripción */}
            <Column50>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Descripción</Label>
                <TextArea
                  style={{ height: "82px" }}
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  required
                />
              </FormRow>
            </Column50>
          </Row>
  
          {/* Precios y Cantidades Mínimas */}
          <h3>Precios y Cantidades Mínimas</h3>
          <Row>
            {/* Precio Normal y Porcentaje Comercio */}
            <Column50>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Precio Normal</Label>
                <Input
                  type="number"
                  name="normalPrice"
                  value={formData.prices.normalPrice}
                  onChange={handleInputChange}
                />
              </FormRow>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Porcentaje Precio Comercio</Label>
                <Input
                  type="number"
                  name="merchantPercentage"
                  value={formData.prices.merchant.percentage}
                  onChange={handleInputChange}
                  min="0"
                  max="90"
                />
              </FormRow>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Cantidad Mínima Precio Comercio</Label>
                <Input
                  type="number"
                  name="merchantMiniumQuantity"
                  value={formData.prices.merchant.miniumQuantity}
                  onChange={handleInputChange}
                  min="0"
                  max="90"
                />
              </FormRow>
            </Column50>
  
            {/* Por Mayor y Distribuidor */}
            <Column50>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Porcentaje Precio por Mayor</Label>
                <Input
                  type="number"
                  name="wholesalerPercentage"
                  value={formData.prices.wholesaler.percentage}
                  onChange={handleInputChange}
                  min="0"
                  max="90"
                />
              </FormRow>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Cantidad Mínima al por Mayor</Label>
                <Input
                  type="number"
                  name="wholesalerMiniumQuantity"
                  value={formData.prices.wholesaler.miniumQuantity}
                  onChange={handleInputChange}
                  min="0"
                  max="90"
                />
              </FormRow>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Porcentaje Precio Distribuidor</Label>
                <Input
                  type="number"
                  name="distributorsPercentage"
                  value={formData.prices.distributors.percentage}
                  onChange={handleInputChange}
                  min="0"
                  max="90"
                />
              </FormRow>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>Cantidad Mínima Precio Distribuidor</Label>
                <Input
                  type="number"
                  name="distributorsMiniumQuantity"
                  value={formData.prices.distributors.miniumQuantity}
                  onChange={handleInputChange}
                  min="0"
                  max="90"
                />
              </FormRow>
            </Column50>
          </Row>
  
          {/* Oferta */}
          <h3>Oferta</h3>
          <Row>
            <Column50>
              <FormRow style={{ marginRight: "20px" }}>
                <Label>¿Habilitar Oferta?</Label>
                <Input
                  type="checkbox"
                  name="enable"
                  checked={formData.offer.enable}
                  onChange={handleInputChange}
                />
              </FormRow>
              {formData.offer.enable && (
                <FormRow style={{ marginRight: "20px" }}>
                  <Label>Porcentaje de Oferta</Label>
                  <Input
                    type="number"
                    name="percentage"
                    value={formData.offer.percentage}
                    onChange={handleInputChange}
                    min="0"
                    max="90"
                  />
                </FormRow>
              )}
            </Column50>
          </Row>
  
          {/* Imágenes */}
          <h3>Imágenes</h3>
          <p>Las imágenes del producto deben ser ingresadas a través de un enlace URL, en lugar de subirlas directamente desde el dispositivo.</p>
          <br/>
          <Row>
            {[1, 2, 3, 4, 5].map((i) => (
              <Column50 key={i}>
                <FormRow>
                  <Label>Imagen {i}</Label>
                  <Input
                    type="text"
                    name={`image${i}`}
                    value={formData.images[i - 1]}
                    onChange={handleInputChange}
                  />
                </FormRow>
              </Column50>
            ))}
          </Row>
          <br/><br/>
          {/* Botón de Enviar */}
          <Button style={{margin:"10px", background:'#d34343'}} type="submit">Guardar Producto</Button>
        </Form>
      </Card>
    </Container>
  );
};
