import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Container, CardForm, TitleForm } from "../../styles/styledComponents"; 

export function CrearTiendaEtapa5() {
  const [logo, setLogo] = useState({ file: null, url: null });
  const [selectedColorGroup, setSelectedColorGroup] = useState(null);
  const [accordionOpen, setAccordionOpen] = useState(true);
  const [enableSubsections, setEnableSubsections] = useState(false);

  const [subsections, setSubsections] = useState([
    { order: "1ro", name: "", enabled: false },
    { order: "2da", name: "", enabled: false },
    { order: "3ra", name: "", enabled: false },
  ]);

  const [menuItems, setMenuItems] = useState([
    { order: "1ro", name: "" },
    { order: "2do", name: "" },
    { order: "3ro", name: "" },
  ]);

  // New state to store selected colors
  const [colors, setColors] = useState({
    primary: "",
    secondary: "",
    tertiary: "",
  });

  const navigate = useNavigate();

  const colorOptions = [
    { label: "Primera opción", colors: ["#BFC8AD", "#90B494", "#718F94"] },
    { label: "Segunda opción", colors: ["#3BA99C", "#69D1C5", "#7EBCE6"] },
    { label: "Tercera opción", colors: ["#F0A6CA", "#EFC3E6", "#F0E6EF"] },
  ];

  // On component mount, load colors, subsections, and logo from localStorage if available
  useEffect(() => {
    const storedColors = localStorage.getItem("storeColors");
    const storedSubsections = localStorage.getItem("storeSubsections");
    const storedLogoURL = localStorage.getItem("storeLogoURL");
    
    if (storedColors) {
      setColors(JSON.parse(storedColors));
    }
    if (storedSubsections) {
      setSubsections(JSON.parse(storedSubsections));
    }
    if (storedLogoURL) {
      setLogo((prevLogo) => ({ ...prevLogo, url: storedLogoURL }));
    }
  }, []);

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    const logoURL = URL.createObjectURL(file);
    setLogo({ file, url: logoURL });
    localStorage.setItem("storeLogoURL", logoURL); // Guardar solo la URL del logo en localStorage
  };

  const handleAccordionToggle = () => {
    setAccordionOpen(!accordionOpen);
  };

  const handleColorGroupSelect = (index) => {
    setSelectedColorGroup(index);
    const selectedColors = colorOptions[index].colors;

    const newColors = {
      primary: selectedColors[0],
      secondary: selectedColors[1],
      tertiary: selectedColors[2],
    };

    setColors(newColors);
    localStorage.setItem("storeColors", JSON.stringify(newColors));
  };

  const handleSubsectionEnable = (index, enabled) => {
    const updatedSubsections = [...subsections];
    updatedSubsections[index].enabled = enabled;
    setSubsections(updatedSubsections);

    // Save subsections to localStorage
    localStorage.setItem("storeSubsections", JSON.stringify(updatedSubsections));
  };

  const handleSubsectionChange = (index, name) => {
    const updatedSubsections = [...subsections];
    updatedSubsections[index].name = name;
    setSubsections(updatedSubsections);

    // Save subsections to localStorage
    localStorage.setItem("storeSubsections", JSON.stringify(updatedSubsections));
  };

  const handleMenuChange = (index, name) => {
    const updatedMenuItems = [...menuItems];
    updatedMenuItems[index].name = name;
    setMenuItems(updatedMenuItems);
  };

  const handleContinue = () => {
    if (selectedColorGroup === null) {
      alert("Por favor seleccione un grupo de colores.");
      return;
    }

    console.log("Colores seleccionados:", colors);
    console.log("Subsecciones seleccionadas:", subsections);
    navigate("/crear-tiendaet6");
  };

  return (
    <Container>
      <CardForm>
        <TitleForm>CONFIGURAR PLANTILLA</TitleForm>
        
        <Section>
          <Label>Logo Tienda</Label>
          <Input type="file" onChange={handleLogoChange} />
          {logo.url && <LogoPreview src={logo.url} alt="Logo preview" />}
        </Section>

        <Section>
          <Label>Seleccione Grupo de Colores</Label>
          <Accordion>
            <AccordionHeader onClick={handleAccordionToggle}>
              {accordionOpen ? "Ocultar Colores" : "Mostrar Colores"}
            </AccordionHeader>
            {accordionOpen && (
              <AccordionContent>
                {colorOptions.map((option, index) => (
                  <div key={index}>
                    <OptionLabel>{option.label}</OptionLabel>
                    <ColorGroup>
                      {option.colors.map((color, colorIndex) => (
                        <ColorOption
                          key={colorIndex}
                          style={{
                            backgroundColor: color,
                            border: selectedColorGroup === index ? "3px solid #000" : "none",
                          }}
                          onClick={() => handleColorGroupSelect(index)}
                        />
                      ))}
                    </ColorGroup>
                  </div>
                ))}
              </AccordionContent>
            )}
          </Accordion>
        </Section>

        <Section>
          <Label>MENÚ DE NAVEGACIÓN</Label>
          {menuItems.map((item, index) => (
            <InputContainer key={index}>
              <span>{item.order}</span>
              <Input
                type="text"
                value={item.name}
                onChange={(e) => handleMenuChange(index, e.target.value)}
              />
            </InputContainer>
          ))}
        </Section>

        <Section>
          <CheckboxContainer>
            <Input
              type="checkbox"
              checked={enableSubsections}
              onChange={(e) => setEnableSubsections(e.target.checked)}
            />
            <span>Habilitar Subsecciones</span>
          </CheckboxContainer>

          {enableSubsections &&
            subsections.map((subsection, index) => (
              <InputContainer key={index}>
                <Input
                  type="checkbox"
                  checked={subsection.enabled}
                  onChange={(e) => handleSubsectionEnable(index, e.target.checked)}
                />
                <span>{subsection.order}</span>
                <Input
                  type="text"
                  value={subsection.name}
                  onChange={(e) => handleSubsectionChange(index, e.target.value)}
                  disabled={!subsection.enabled}
                />
              </InputContainer>
            ))}
        </Section>

        <ContinueButton onClick={handleContinue}>Continuar</ContinueButton>
      </CardForm>
    </Container>
  );
}

// Estilos con styled-components
const Section = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
`;

const Input = styled.input`
  margin-top: 10px;
  padding: 8px;
  font-size: 14px;
`;

const Accordion = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const AccordionHeader = styled.div`
  background-color: #ccc;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background-color: #bbb;
  }
`;

const AccordionContent = styled.div`
  padding: 10px;
  background-color: #f9f9f9;
`;

const OptionLabel = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const ColorGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`;

const ColorOption = styled.div`
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: border 0.15s ease;

  &:hover {
    border: 3px solid #000;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  span {
    min-width: 40px;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

const LogoPreview = styled.img`
  margin-top: 10px;
  width: 100px;
  height: 100px;
  object-fit: contain;
`;

const ContinueButton = styled.button`
  padding: 10px 20px;
  background-color: #000;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  
  &:hover {
    background-color: #333;
  }
`;

export default CrearTiendaEtapa5;