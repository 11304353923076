import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { Sidebar } from "./Sidebar";
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styled from "styled-components";

const LayoutContainer = styled.div`
  display: flex;
`;

const Content = styled.div`
  flex-grow: 1;
  padding-top: 50px; /* Altura del Navbar */
  background-color: #f8f9fa;
  transition: margin-left 0.3s ease;
  margin-left: ${({ isOpen }) => (isOpen ? '200px' : '80px')};
  min-height: calc(100vh - 50px);
  width: 100%;
`;

export const ProtectedLayout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(true);

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      navigate("/");
    }
  }, [navigate]);

  // Ocultar el Sidebar y Navbar en login y register
  if (location.pathname === '/login' || location.pathname === '/register') {
    return <Outlet />;
  }

  return (
    <>
      <Navbar />
      <LayoutContainer>
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <Content isOpen={sidebarOpen}>
          <Outlet />
        </Content>
      </LayoutContainer>
    </>
  );
};

export default ProtectedLayout;
