import axios from "axios";

const API_URL = process.env.REACT_APP_ECOMMERCE_API_URL; 

export const createOrder = async (orderData, storeId) => {
  try {
    const companyId = localStorage.getItem("companyId");
    console.log("companyid:", companyId);
    console.log("storeid:", storeId);
    console.log("Order datos:", orderData);

    const response = await axios.post(`${API_URL}/Order`, orderData, {
      headers: {
        companyId,
        storeId,
      },
    });

    console.log("Order created:", response);
    return response.data;
  } catch (error) {
    console.error("Error creating order:", error);
    throw error;
  }
};

// Update an existing order (PUT /Order/{orderId})
export const updateOrder = async (formData, orderId, storeId) => {
  try {
    const companyId = localStorage.getItem("companyId");
    const response = await axios.put(
      `${API_URL}/Order/${orderId}`, formData, {
        headers: {
          companyId,
          storeId,
        },
      });
    return response.data;
  } catch (error) {
    console.error(`Error updating order with ID ${orderId}:`, error);
    throw error;
  }
};

// Get a single order by ID (GET /Order/{orderId})
export const getOrder = async (orderId) => {
  try {
    const response = await axios.get(`${API_URL}/Order/${orderId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching order with ID ${orderId}:`, error);
    throw error;
  }
};

// Delete a order by ID (DELETE /Order/{orderId})
export const deleteOrder = async (orderId) => {
  try {
    const response = await axios.delete(`${API_URL}/Order/${orderId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting order with ID ${orderId}:`, error);
    throw error;
  }
};

// Get all orders (POST /GetAll)
export const getAllOrders = async (filterData, storeId) => {
  const companyId = localStorage.getItem('companyId');

  try {
    const response = await axios.post(`${API_URL}/Order/GetAll`, 
      {
        filter: {
          createDateTime: {
            startDateTime: filterData.startDateTime, // Pass the start date from filterData
            endDateTime: filterData.endDateTime, // Pass the end date from filterData
          }
        }
      },
      {
        headers: {
          storeId,
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching all orders:', error);
    throw error;
  }
};