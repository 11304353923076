import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getUsers, updateUser, deleteUser } from "./services/user.services";
import { ErrorAlert } from "../../components/ErrorAlert";
import { Container, Card, TitleContainer, Title, Title2, Breadcrumb } from "../../styles/styledComponents";

export function UserActive() {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState(null); // Estado para almacenar el error si ocurre
  const { userId } = location.state || {};
  const [showRejectReason, setShowRejectReason] = useState(false); // Estado para mostrar el campo de razón de rechazo
  const [rejectReason, setRejectReason] = useState(""); // Estado para almacenar la razón de rechazo

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
  });

  useEffect(() => {
    const fetchUser = async () => {
      if (userId) {
        try {
          const userData = await getUsers(userId);
          if (userData) {
            setFormData({
              firstName: userData.data.firstName,
              lastName: userData.data.lastName,
              email: userData.data.email,
              phone: userData.data.phone,
              companyName: userData.data.company?.identificationNumber || "",
              reason: userData.data.reason
            });
          } else {
            console.error("Error: No se recibió el usuario en la respuesta.");
          }
        } catch (error) {
          console.error("Error fetching user:", error);
          setError(error); // Almacena el error en el estado para que el componente ErrorAlert lo procese
        }
      }
    };
    fetchUser();
  }, [userId]);

  const handleConfirm = async () => {
    try {
      if (userId) {
        await updateUser(userId, { isEnabled: true });
        alert("La cuenta ha sido habilitada con éxito.");
        navigate(-1); // Redirige a la página anterior
      }
    } catch (error) {
      console.error("Error enabling user:", error);
      setError(error);
    }
  };

  const handleReject = () => {
    setShowRejectReason(true); // Muestra el campo de razón de rechazo
  };

  const handleRejectSubmit = async () => {
    try {
      if (userId && rejectReason.trim()) {
        await deleteUser(userId, { reason: rejectReason });
        alert("La cuenta ha sido rechazada y eliminada.");
        navigate(-1); // Redirige a la página anterior
      } else {
        alert("Por favor, proporciona una razón para rechazar la cuenta.");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      setError(error);
    }
  };

  return (
    <Container>
      {error && <ErrorAlert error={error} />}
      <TitleContainer>
        <Title>Usuarios</Title>
        <Breadcrumb
          items={[
            { name: "Inicio", link: "/tienda" },
            { name: "Usuarios", link: "/usuarios" },
            { name: "Detalle", link: "/ver-usuario" },
          ]}
        />
      </TitleContainer>
      <Card>
        <Title2>Activar usuario</Title2>
        <InfoItem>
          <strong>Nombres: </strong>
          {formData.firstName}
        </InfoItem>
        <InfoItem>
          <strong>Apellidos: </strong>
          {formData.lastName}
        </InfoItem>
        <InfoItem>
          <strong>Teléfono: </strong>
          {formData.phone}
        </InfoItem>
        <InfoItem>
          <strong>Correo: </strong>
          {formData.email}
        </InfoItem>
        <InfoItem>
          <strong>Rut de empresa (Razon social): </strong>
          {formData.companyName}
        </InfoItem>
        <InfoItem>
          <strong>Razon de inscripción: </strong>
          {formData.reason}
        </InfoItem>
        <ButtonContainer>
          <Button onClick={handleConfirm}>Confirmar cuenta</Button>
          <Button onClick={handleReject}>Rechazar cuenta</Button>
        </ButtonContainer>
        {showRejectReason && (
          <RejectReasonContainer>
            <RejectReasonLabel>Razón para rechazar:</RejectReasonLabel>
            <RejectReasonInput
              type="text"
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
              placeholder="Escribe la razón del rechazo"
            />
            <Button onClick={handleRejectSubmit}>Enviar razón y rechazar</Button>
          </RejectReasonContainer>
        )}
      </Card>
    </Container>
  );
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const Button = styled.button`
  background-color: #1976d2;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 6px 16px;
  cursor: pointer;
  width: 49%;
  transition: background-color 0.3s ease, transform 0.2s ease;
  &:hover {
    background-color: #1770ca;
  }
  &:active {
    transform: scale(0.95);
  }
`;

const RejectReasonContainer = styled.div`
  margin-top: 15px;
`;

const RejectReasonLabel = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
`;

const RejectReasonInput = styled.input`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  font-size: 14px;
`;


// const Title = styled.h1`
//   margin-bottom: 20px;
// `;

// const InfoContainer = styled.div`
//   margin-top: 50px;
//   border: 3px solid #ddd;
//   border-radius: 5px;
//   padding: 30px;
//   width: 850px;
//   background-color: #f9f9f9;
// `;

// const InfoTitle = styled.h2`
//   margin-bottom: 15px;
// `;

const InfoItem = styled.p`
  margin: 5px 0;
  font-size: 16px;
  line-height: 1.5;
`;
