import React from "react";

import styled from "styled-components";
import logo from '../assets/logo.png';
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";


// Pie de página (Footer) actualizado para reflejar el diseño de la imagen
export const Footer = () => {
    return (
        <FooterContainer>
            <FooterTop>
                <FooterSection>
                    <FooterLogo src={logo} alt="Enlace Mercado" />
                    <ContactInfo>
                        <p>Santiago de Chile</p>
                        <p><a href="mailto:rviana@enlacemercado.cl">rviana@enlacemercado.cl</a></p>
                        <p><a href="tel:+56930865137">+56930865137</a></p>
                    </ContactInfo>
                    <SocialIcons>
                        <a href="https://web.facebook.com/people/EnlaceMercado/100070521127082/"><i className="fab fa-facebook-f"><FaFacebook />                        </i></a>
                        <a href="https://www.instagram.com/enlacemercado?igshid=MzRlODBiNWFlZA%3D%3D"><i className="fab fa-instagram"><FaInstagram /></i></a>
                        <a href="https://www.youtube.com/@EnlaceMercado-tz3ei"><i className="fab fa-youtube"><FaYoutube /></i></a>
                    </SocialIcons>
                </FooterSection>
                <FooterSection>
                    
                    <h4>INFORMACIÓN</h4>
                    <ul>
                        <li><a href="/quienes-somos">Quienes somos</a></li>
                        <li><a href="mailto:consultas@enlacemercado.cl">Contáctenos</a></li>
                        <li><a href="/terminos-y-condiciones">Términos y condiciones</a></li>                                  
                    </ul>
                </FooterSection>
            </FooterTop>
            <FooterBottom>
                <p>© 2023 copyright by Enlace Mercado. Todos los derechos reservados.</p>
            </FooterBottom>
        </FooterContainer>
    );
};

const FooterContainer = styled.footer`
    background-color: #353535;
    padding: 40px 20px; // Más espaciado para que se vea más amplio
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
`;

const FooterTop = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    flex-wrap: wrap;
    gap: 20px;
`;

const FooterSection = styled.div`
    flex: 1;
    min-width: 200px; // Para que las secciones no colapsen en pantallas pequeñas
    padding: 10px;
    h4 {
        color: #e95b5b;
        margin-bottom: 15px;
        text-transform: uppercase;
    }
    ul {
        list-style: none;
        padding: 0;
    }
    li {
        margin-bottom: 10px;
    }
    a {
        color: #fff;
        text-decoration: none;
        transition: color 0.3s;
        &:hover {
            color: #e95b5b; // Color de hover
        }
    }
`;

const FooterLogo = styled.img`
    width: 150px;
`;

const ContactInfo = styled.div`
    p {
        margin: 5px 0;
        font-size: 14px;
    }
    a {
        color: #fff;
        text-decoration: none;
        &:hover {
            color: #e95b5b; // Color de hover para los contactos
        }
    }
`;

const SocialIcons = styled.div`
    margin-top: 15px;
    a {
        color: #fff;
        margin-right: 10px;
        font-size: 18px;
        transition: color 0.3s;
        &:hover {
            color: #e95b5b;
        }
    }
`;

const FooterBottom = styled.div`
    margin-top: 20px;
    border-top: 1px solid #fff;
    padding-top: 10px;
    font-size: 14px;
    text-align: center;
    width: 100%;
`;
const NewsletterForm = styled.form`
    display: flex;
    input {
        padding: 10px;
        flex: 1;
        border: 1px solid #ccc;
        border-radius: 4px 0 0 4px;
    }
    button {
        background-color: #4c2a85;
        color: white;
        border: none;
        padding: 10px;
        border-radius: 0 4px 4px 0;
        cursor: pointer;
    }
`;