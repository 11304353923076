import axios from 'axios';

const API_URL = process.env.REACT_APP_COMPANYMANAGEMENT_API_URL; 

// Servicios para Company
export const createCompany = (companyData) => {
    return axios.post(`${API_URL}/Company`, companyData);
};

export const updateCompany = (companyId, companyData) => {
    return axios.put(`${API_URL}/Company/${companyId}`, companyData);
};

export const getCompany = () => {
  // Recuperar el companyId desde localStorage
  const companyId = localStorage.getItem('companyId');
  // Verificar que el companyId exista en localStorage antes de realizar la solicitud
  if (!companyId) {
    throw new Error('No se encontró el ID de la empresa en localStorage');
  }

  // Realizar la solicitud a la API usando el companyId
  return axios.get(`${API_URL}/Company/${companyId}`);
};

export const deleteCompany = (companyId) => {
    return axios.delete(`${API_URL}/Company/${companyId}`);
};

export const getAllCompanies = () => {
    return axios.post(`${API_URL}/Company/GetAll`);
};