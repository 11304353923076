import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export function CrearTiendaEtapa6() {
  const [imagen1, setImagen1] = useState(null);
  const [imagen2, setImagen2] = useState(null);
  const [imagen3, setImagen3] = useState(null);
  
  const navigate = useNavigate();

  // Cargar imágenes desde localStorage al iniciar el componente
  useEffect(() => {
    const storedImage1 = localStorage.getItem("sliderImage1");
    const storedImage2 = localStorage.getItem("sliderImage2");
    const storedImage3 = localStorage.getItem("sliderImage3");

    if (storedImage1) setImagen1(storedImage1);
    if (storedImage2) setImagen2(storedImage2);
    if (storedImage3) setImagen3(storedImage3);
  }, []);

  // Función para gestionar los cambios de cada imagen
  const handleImageChange = (e, setImage, storageKey) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
      localStorage.setItem(storageKey, imageUrl); // Guardar en localStorage
    }
  };

  const handleContinue = () => {
    // Aquí podrías enviar los datos o navegar a la siguiente etapa
    navigate("/crear-tiendaet7"); // Cambia esto a la ruta que necesites
  };

  return (
    <Container>
      <Title>CONFIGURAR PLANTILLA</Title>
      <SubTitle>Slider de imagen</SubTitle>

      <Section>
        <Label>Imagen 1</Label>
        <Input
          type="file"
          accept="image/*"
          onChange={(e) => handleImageChange(e, setImagen1, "sliderImage1")}
        />
        {imagen1 && <LogoPreview src={imagen1} alt="Imagen 1 preview" />}
        
        <Label>Imagen 2</Label>
        <Input
          type="file"
          accept="image/*"
          onChange={(e) => handleImageChange(e, setImagen2, "sliderImage2")}
        />
        {imagen2 && <LogoPreview src={imagen2} alt="Imagen 2 preview" />}
        
        <Label>Imagen 3</Label>
        <Input
          type="file"
          accept="image/*"
          onChange={(e) => handleImageChange(e, setImagen3, "sliderImage3")}
        />
        {imagen3 && <LogoPreview src={imagen3} alt="Imagen 3 preview" />}
      </Section>

      <ContinueButton onClick={handleContinue}>Continuar</ContinueButton>
    </Container>
  );
}

// Estilos con styled-components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const Title = styled.h2`
  margin-bottom: 5px;
`;

const SubTitle = styled.h3`
  margin-bottom: 20px;
`;

const Section = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
`;

const Input = styled.input`
  margin-top: 10px;
  padding: 8px;
  font-size: 14px;
`;

const LogoPreview = styled.img`
  margin-top: 0px;
  width: 200px;
  height: 100px;
  object-fit: contain;
`;

const ContinueButton = styled.button`
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #333;
  }
`;

export default CrearTiendaEtapa6;
