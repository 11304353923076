import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { createStore, createWebStore } from "./services/shop.service";
import axios from "axios"; // Import axios for making HTTP requests
import {
  Container,
  CardForm,
  TitleForm,
  InfoForm,
  Row,
  Column50,
} from "../../styles/styledComponents";

export const CrearTiendaEtapa9 = () => {
  const [status, setStatus] = useState(false);
  const [storeData, setStoreData] = useState({});
  const [webStoreData, setWebStoreData] = useState({});
  const [logo, setLogo] = useState({ file: null, url: null }); // Estado para almacenar el archivo y la URL del logo
  const [sliderFiles, setSliderFiles] = useState([
    { file: null, url: null },
    { file: null, url: null },
    { file: null, url: null },
  ]); // Estado para almacenar las imágenes del slider
  const navigate = useNavigate();

  useEffect(() => {
    // Recuperar las imágenes del slider del localStorage
    const sliderImage1 = localStorage.getItem("sliderImage1");
    const sliderImage2 = localStorage.getItem("sliderImage2");
    const sliderImage3 = localStorage.getItem("sliderImage3");
    const storeColors = JSON.parse(localStorage.getItem("storeColors")) || [];
    console.log("storeColors", storeColors);
    const sliderFiles = [
      { file: null, url: sliderImage1 },
      { file: null, url: sliderImage2 },
      { file: null, url: sliderImage3 },
    ];
    setSliderFiles(sliderFiles);

    setWebStoreData({
      storeName: localStorage.getItem("nombre") || null,
      descriptionWhoWeAre: localStorage.getItem("descrquiensomos") || null,
      descriptionMision: localStorage.getItem("descrempresa") || null,
      descriptionVision: localStorage.getItem("desctermycond") || null,
      templateCode: localStorage.getItem("templateCode") || null,
      primaryColor: storeColors.primary || null, // Acceder al primer elemento de storeColors
      secondaryColor: storeColors.secondary || null,
      tertiaryColor: storeColors.tertiary || null,
    });

    setStoreData({
      storeName: localStorage.getItem("nombre") || null,
      domain: localStorage.getItem("dominio") || null,
      shippingDetails: JSON.parse(localStorage.getItem("shippingDetails"))|| null,
      pickUpDetails: JSON.parse(localStorage.getItem("pickUpDetails"))|| null,
      status: "ACTIVATE",
      transbank: JSON.parse(localStorage.getItem("transbank")) || null,
      bankAccount: JSON.parse(localStorage.getItem("bankAccount")) || null,
      hostWebpay: JSON.parse(localStorage.getItem("hostWebpay")) || false,
      contact: {
        phone: localStorage.getItem("telefono") || null,
        email: localStorage.getItem("email") || null,
        addres: localStorage.getItem("direccion") || null,
        socialNetwork: {
          additionalProp1: localStorage.getItem("redsocialfb") || null,
          additionalProp2: localStorage.getItem("redsocialig") || null,
          additionalProp3: localStorage.getItem("redsocialtw") || null,
        },
        
      },
      description: {
        whoWeAre: localStorage.getItem("descrempresa"),
        mision: localStorage.getItem("descrquiensomos"),
        vision:  localStorage.getItem("desctermycond")
      }
    });
  }, []);
  console.log("datos;",storeData)

  const handleCheckboxChange = (e) => {
    setStatus(e.target.checked);
  };

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setLogo({ file, url });
    }
  };

  const handleSliderUpload = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      const updatedSliderFiles = [...sliderFiles];
      updatedSliderFiles[index] = { file, url };
      setSliderFiles(updatedSliderFiles);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log("Datos para crear web:", webStoreData);
      const finalData = {
        ...storeData,
        // storeFile: responseCreateWebStore.data.storeFile,
        status: "ACTIVATE",
      };
      // Ejecuta createWebStore y espera a que termine
      const responseCreateStore = await createStore(finalData);
      console.log("responseCreateStore", responseCreateStore);
      if (responseCreateStore.status === 200) {
        console.log("Datos para crear store:", finalData);

        const formData = new FormData();
        formData.append("storeId", responseCreateStore.data.storeId);
        formData.append("storeName", webStoreData.storeName);
        formData.append(
          "descriptionWhoWeAre",
          webStoreData.descriptionWhoWeAre
        );
        formData.append("descriptionMision", webStoreData.descriptionMision);
        formData.append("descriptionVision", webStoreData.descriptionVision);
        formData.append("templateCode", webStoreData.templateCode);
        formData.append("primaryColor", webStoreData.primaryColor);
        formData.append("secondaryColor", webStoreData.secondaryColor);
        formData.append("tertiaryColor", webStoreData.tertiaryColor);

        // Agrega el logo como archivo
        if (logo.file) {
          formData.append("logo", logo.file);
        } else {
          console.error("Logo is not a valid file");
          return;
        }

        // Agrega las imágenes del slider como archivos
        sliderFiles.forEach((slider, index) => {
          if (slider.file) {
            formData.append(`imagesFiles`, slider.file);
          }
        });
        console.log("formData", formData);

        console.log(
          "responseCreateStore.storeId",
          responseCreateStore.data.storeId
        );
        const responseCreateWebStore = await createWebStore(
          formData,
          responseCreateStore.data.storeId,
          {
            responseType: "blob",
          }
        );

        // console.log("responseCreateWebStore", responseCreateWebStore);
        if (responseCreateStore.status === 200) {
          alert("¡Tienda creada exitosamente!");
          navigate("/tienda");
        } else {
          alert("Hubo un error al crear la tienda");
        }
      }
      // // Verifica si createWebStore fue exitoso
      // if (responseCreateStore.status === 200) {
      //   // Construye finalData con los datos de responseCreateWebStore
      //   console.log("Datos para crear store:", finalData);

      //   // Ejecuta createStore y espera a que termine
      //   const responseCreateWebStore = await createWebStore(formData, responseCreateStore.storeId, {
      //     responseType: "blob",
      //   });

      // // Verifica si createStore fue exitoso
      // if (responseCreateStore.status === 200) {
      //   alert("¡Tienda creada exitosamente!");
      //   navigate("/");
      // } else {
      //   alert("Hubo un error al crear la tienda");
      // }
      // } else {
      //   alert("Hubo un error al crear la web store");
      // }
    } catch (error) {
      console.error("Error:", error);
          // Extraer información del error
        if (axios.isAxiosError(error) && error.response) {
      const { title, status } = error.response.data || {};
      alert(`Error: ${title || "Ocurrió un error inesperado"}\nCódigo de estado: ${status || "N/A"}`);
    } else {
      alert("Ocurrió un error inesperado");
    }
    }
  };

  return (
    <Container>
      <Card>
        <Title>Revisa tu Información</Title>

        <InfoGroup>
          <Label>Nombre de la tienda:</Label>
          <Value>{storeData.storeName}</Value>
        </InfoGroup>

        <InfoGroup>
          <Label>Dominio:</Label>
          <Value>{storeData.domain}</Value>
        </InfoGroup>
        <Row>
          {/* Información Bancaria */}
          {storeData.bankAccount && (
            <Column50>
              <SubTitle>Información Bancaria:</SubTitle>
              <InfoGroup>
                <Label>Banco:</Label>
                <Value>{storeData.bankAccount.bankName}</Value>
              </InfoGroup>
              <InfoGroup>
                <Label>Tipo de Cuenta:</Label>
                <Value>{storeData.bankAccount.type}</Value>
              </InfoGroup>
              <InfoGroup>
                <Label>Número de Cuenta:</Label>
                <Value>{storeData.bankAccount.number}</Value>
              </InfoGroup>
              <InfoGroup>
                <Label>Email:</Label>
                <Value>{storeData.bankAccount.email}</Value>
              </InfoGroup>
            </Column50>
          )}

          {storeData.shippingDetails && (
            <Column50>
              <SubTitle>Metodos de envio:</SubTitle>
              <InfoGroup>
                <Label>Retiro en tienda:</Label>
                <Value>{storeData.pickUpDetails.enable ? "Sí" : "No"}</Value>
              </InfoGroup>
              <InfoGroup>
                <Label>Entrega a domicilio:</Label>
                <Value>{storeData.shippingDetails.enable  ? "Sí" : "No"}</Value>
              </InfoGroup>
              <InfoGroup>
              <Label>Precio de envio:</Label>
              <Value>{storeData.shippingDetails.price}</Value>
              </InfoGroup>
            </Column50>
          )}

          {/* Información de Webpay */}
          {storeData.transbank && (
            <Column50>
              <SubTitle>Información de Webpay:</SubTitle>
              <InfoGroup>
                <Label>Token Webpay:</Label>
                <Value>{storeData.transbank.token}</Value>
              </InfoGroup>
              <InfoGroup>
                <Label>Webpay habilitado:</Label>
                <Value>{storeData.transbank.enable ? "Sí" : "No"}</Value>
              </InfoGroup>
            </Column50>
          )}

          {/* Información de Contacto */}
          {storeData.contact && (
            <>
              <Column50>
                <SubTitle>Información de Contacto:</SubTitle>
                <InfoGroup>
                  <Label>Teléfono:</Label>
                  <Value>{storeData.contact.phone}</Value>
                </InfoGroup>
                <InfoGroup>
                  <Label>Email de Contacto:</Label>
                  <Value>{storeData.contact.email}</Value>
                </InfoGroup>
                <InfoGroup>
                  <Label>Dirección:</Label>
                  <Value>{storeData.contact.addres}</Value>
                </InfoGroup>
              </Column50>

              {/* Redes Sociales */}
              <Column50>
                <SubTitle>Redes Sociales:</SubTitle>
                <InfoGroup>
                  <Label>Facebook:</Label>
                  <Value>
                    {storeData.contact.socialNetwork?.additionalProp1 || "N/A"}
                  </Value>
                </InfoGroup>
                <InfoGroup>
                  <Label>Instagram:</Label>
                  <Value>
                    {storeData.contact.socialNetwork?.additionalProp2 || "N/A"}
                  </Value>
                </InfoGroup>
                <InfoGroup>
                  <Label>Twitter:</Label>
                  <Value>
                    {storeData.contact.socialNetwork?.additionalProp3 || "N/A"}
                  </Value>
                </InfoGroup>
              </Column50>
            </>
          )}
        </Row>
        {/* Logo */}
        <InfoGroup>
          <Label>Logo:</Label>
          <input
            type="file"
            accept="image/png"
            onChange={handleLogoUpload}
            required
          />
          {logo.url && (
            <img
              src={logo.url}
              alt="Logo de la tienda"
              style={{ width: "100px", height: "100px" }}
            />
          )}
        </InfoGroup>

        {/* Imágenes del Slider */}
        {sliderFiles.length > 0 && (
          <>
            <SubTitle>Imágenes del Slider:</SubTitle>
            {sliderFiles.map((slider, index) => (
              <InfoGroup key={index}>
                <Label>Imagen {index + 1}:</Label>
                <input
                  type="file"
                  accept="image/png"
                  onChange={(e) => handleSliderUpload(e, index)}
                  required
                />
                {slider.url && (
                  <img
                    src={slider.url}
                    alt={`Slider ${index + 1}`}
                    style={{ width: "100px", height: "100px" }}
                  />
                )}
              </InfoGroup>
            ))}
          </>
        )}

        {/* Colores Seleccionados */}
        {storeData.templateCode && (
          <>
            <SubTitle>Template seleccionado:</SubTitle>
            <InfoGroup>
              <Label>Seleccionaste:</Label>
              <Value>{storeData.templateCode} </Value>
            </InfoGroup>
          </>
        )}
        {/* Colores Seleccionados */}
        {storeData.colors && (
          <>
            <SubTitle>Colores Seleccionados:</SubTitle>
            <InfoGroup>
              <Label>Primaria:</Label>
              <ColorBox color={storeData.colors.primary} />
            </InfoGroup>
            <InfoGroup>
              <Label>Secundaria:</Label>
              <ColorBox color={storeData.colors.secondary} />
            </InfoGroup>
            <InfoGroup>
              <Label>Terciaria:</Label>
              <ColorBox color={storeData.colors.tertiary} />
            </InfoGroup>
          </>
        )}
            {storeData.description && (
              <Column50>
                <SubTitle>Redes Sociales:</SubTitle>
                <InfoGroup>
                  <Label>Quienes somos:</Label>
                  <Value>
                    {storeData.description.whoWeAre ||"N/A"}
                  </Value>
                </InfoGroup>
                <InfoGroup>
                  <Label>Mision:</Label>
                  <Value>
                    {storeData.description.mision ||"N/A"}
                  </Value>
                </InfoGroup>
                <InfoGroup>
                  <Label>Vision:</Label>
                <Value>
                    {storeData.description.mision ||"N/A"}
                  </Value>
                </InfoGroup>
              </Column50>
            )}
        <FinalText>
          Si todo es correcto, haz clic en Finalizar para terminar el proceso de
          crear tu tienda.
        </FinalText>

        <SubmitButton type="button" onClick={handleSubmit}>
          Finalizar
        </SubmitButton>
      </Card>
    </Container>
  );
};

// Estilos con styled-components
// const Container = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
//   background-color: #f2f2f2;
// `;

const Card = styled.div`
  justify-content: center;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 80%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 40px;
  font-size: 14px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const SubTitle = styled.h3`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const InfoGroup = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
`;

const Label = styled.span`
  font-weight: bold;
`;

const Value = styled.span`
  color: #555;
`;

const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const CheckboxLabel = styled.label`
  font-size: 16px;
`;

const FinalText = styled.p`
  font-size: 14px;
  color: #333;
  margin-bottom: 20px;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #555;
  }
`;

const ColorBox = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${(props) => props.color};
  border: 1px solid #ccc;
  margin-left: 10px;
`;

export default CrearTiendaEtapa9;
