import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Container,
  CardForm,
  TitleForm,
  Form,
  FormRow,
  Input,
  Label,
  Title3
} from "../../../styles/styledComponents";

export function ShopShipping({ storeId, initialData, onStoreDataUpdate }) {
  // Usar initialData para establecer los valores iniciales
  const [selectedPayments, setSelectedPayments] = useState({
    pickup: initialData?.pickUpDetails?.enable ?? false,  // Cambiado a `false` por defecto
    shipping: initialData?.shippingDetails?.enable ?? false, // Cambiado a `false` por defecto
  });

  const [formData, setFormData] = useState({
    price: initialData?.shippingDetails?.price || "", // Mantener precio inicial
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSelectedPayments((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  useEffect(() => {
    // Actualizar el estado de la tienda cuando cambian los detalles del envío o retiro
    onStoreDataUpdate({
      shippingDetails: {
        price: formData.price,
        enable: selectedPayments.shipping,
      },
      pickUpDetails: {
        enable: selectedPayments.pickup,
      },
    });
  }, [formData, selectedPayments, onStoreDataUpdate]); // Dependencias correctas para el useEffect

  return (
    <>
        <Title3>Ingrese métodos de envío</Title3>

        <Form>
          <FormRow>
            <CheckboxLabel>
              <Checkbox
                type="checkbox"
                name="pickup"
                checked={selectedPayments.pickup} // Asegúrate de que este valor se refleje correctamente
                onChange={handleCheckboxChange}
              />
              Retiro en tienda
            </CheckboxLabel>
          </FormRow>
          <FormRow>
            <CheckboxLabel>
              <Checkbox
                type="checkbox"
                name="shipping"
                checked={selectedPayments.shipping} // Asegúrate de que este valor se refleje correctamente
                onChange={handleCheckboxChange}
              />
              Entrega a domicilio
            </CheckboxLabel>
          </FormRow>

          {selectedPayments.shipping && (
            <FormRow>
              <Label>Precio</Label>
              <Input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleInputChange}
              />
            </FormRow>
          )}
        </Form>
    </>
  );
}

// Estilos
const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const CheckboxLabel = styled.label`
  margin-right: 20px;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #555;
  }
`;
