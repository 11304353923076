import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Title3, Form, FormRow, Label, Input, TextArea } from "../../../styles/styledComponents";

export function ShopAbout({ storeId, initialData, onStoreDataUpdate }) {
  const [descripcionQuienesSomos, setDescripcionQuienesSomos] = useState(initialData?.description.whoWeAre || "");
  const [descripcionMision, setDescripcionMision] = useState(initialData?.description.mision || "");
  const [descripcionVision, setDescripcionVision] = useState(initialData?.description.vision || "");

  // Actualizar el estado principal cada vez que cambie un campo
  useEffect(() => {
    onStoreDataUpdate({
      descriptionWhoWeAre: descripcionQuienesSomos,
      descriptionMision: descripcionMision,
      descriptionVision: descripcionVision,
    });
  }, [descripcionQuienesSomos, descripcionMision, descripcionVision, onStoreDataUpdate]);

  return (
    <>
      <Title3>Ingrese datos de empresa</Title3>
      <Form>
        <FormRow>
          <Label>Quienes somos</Label>
          <TextArea value={descripcionQuienesSomos} onChange={(e) => setDescripcionQuienesSomos(e.target.value)}
          />
        </FormRow>
        <FormRow>
          <Label>Misión</Label>
          <TextArea value={descripcionMision} onChange={(e) => setDescripcionMision(e.target.value)}
          />
        </FormRow>
        <FormRow>
          <Label>Visión</Label>
          <TextArea value={descripcionVision} onChange={(e) => setDescripcionVision(e.target.value)}
          />
        </FormRow>
      </Form>
    </>
  );
}
