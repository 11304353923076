import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { FaRegEye } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom"; // Asegúrate de usar Link y useLocation para navegar

// Contenedor reutilizable
export const Container = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: start;
  font-family: 'Roboto', sans-serif;
  background-color: #f8f9fa;

`;
export const Card = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  margin-bottom: 20px;
`;
export const CardForm = styled.div`
  justify-content: center;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 60%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0 auto; 
  margin-top: 40px;
  font-size: 14px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;  // Título a la izquierda y breadcrumb a la derecha
  align-items: center;
  width: 100%;
  margin-bottom: 20px;  // Ajuste de margen inferior
`;
export const Title = styled.h1`
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
`;
export const Title2 = styled.h2`
  text-align: left;
  font-weight: 500;
  margin: 0;
`;
export const Title3 = styled.h3`
  text-align: left;
  font-weight: 500;
  margin: 0;
`;
export const TitleForm = styled.h1`
  font-weight: 500;
  font-size: 24px;
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
`;
export const InfoForm = styled.h1`
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  margin-bottom: 10px;
  text-align: justify;
`;
// Botón reutilizable
export const Button = styled.button`
  font-family: 'Roboto', sans-serif;
  background-color: #1976d2;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 6px 16px;
  cursor: pointer;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.75;
  min-width: 64px;
  margin-left: auto;
  transition: background-color 0.3s ease, transform 0.2s ease;
  &:hover {
    background-color: #1770ca;
  }
  &:active {
    transform: scale(0.95); /* Pequeño efecto de "clic" al hacer click */
  }
`;

// Tabla reutilizable
export const TableWrapper = styled.div`
  margin-top: 10px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`;
export const Table = styled.table`
  width: 100%; 
  border-collapse: collapse;
  font-size: 14px;
  font-weight: 400;
`;

export const TableHeader = styled.thead`
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

export const TableHeaderCell = styled.th`
  padding: 12px;
  font-weight: 500;
  text-align: left;
  vertical-align: middle;
  width: 150px;
  &:nth-last-child(1) {
    text-align: right; 
  }
  svg {
    color: #505050;
    vertical-align: middle;
    padding-bottom: 2.2px;
    border: 2px solid transparent;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: #f8f8f8;
        border: 2px solid #f8f8f8;
        border-radius: 5px;
    }
  }
`;

export const TableCell = styled.td`
  padding: 12px;
  text-align: left;
  &:nth-last-child(1) {
    text-align: right;
  }
`;

// Acción reutilizable
export const ActionButtonGroup = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

export const ActionButton = styled.button`
  background-color: #f0f0f0;
  border: none;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const Column50 = styled.div`
  flex: 0 0 auto;
  width: 50%;
  @media (max-width: 768px) {
    flex: 0 0 auto;
    width: 100%;
  }
`;
export const Column40 = styled.div`
  flex: 0 0 auto;
  width: 33.333333%;
  @media (max-width: 768px) {
    flex: 0 0 auto;
    width: 100%;
  }
`;
export const Column100 = styled.div`
  flex: 0 0 auto;
  width: 100%;
`;
// Formulario reutilizable
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;
export const FormGroup = styled.div`
  margin-bottom: 15px;
`;
export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 8px;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const Label = styled.label`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
`;

export const Input = styled.input`
  width: 70%;
  padding: 10px;
  background-color: #f8f8f8;
  border: 1px solid #fff;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  box-sizing: border-box;
  &:focus {
    border: 1px solid #ccc;
    background-color: transparent;
    outline: none;
  }
  @media (max-width: 768px) {
    width: 100%; 
  }
`;
export const Select = styled.select`
  padding: 10px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  background-color: #f8f8f8;
  border: 1px solid #fff;
  font-family: 'Roboto', sans-serif;
  width: 70%;;
  &:focus {
    border: 1px solid #ccc;
    background-color: transparent;
    outline: none;
  }
  @media (max-width: 768px) {
    width: 100%; 
  }
`;
export const TextArea = styled.textarea`
  width: 70%;
  padding: 10px;
  background-color: #f8f8f8;
  border: 1px solid #fff;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;

  &:focus {
    border: 1px solid #ccc;
    background-color: transparent;
    outline: none;
  }
  @media (max-width: 768px) {
    width: 100%; 
  }
`;

export const ViewIcon = FaRegEye;  // Reutilizar íconos

const BreadcrumbContainer = styled.div`
  display: flex !important;
  align-self: flex-end !important;
`;

const BreadcrumbItem = styled(Link)`
  margin-right: 10px;
  color: ${(props) => (props.isActive ? "#1976d2" : "#888")};  /* Diferente color si es la página actual */
  text-decoration: none;
  font-size: 14px;

  &:hover {
    color: ${(props) => (props.isActive ? "#1976d2" : "#555")};  /* Hover para las páginas no activas */
  }

  &:last-child {
    color: #1976d2;  /* Color especial para la página actual */
    pointer-events: none;  /* Deshabilitar clics en la página actual */
  }
`;

const BreadcrumbSeparator = styled.span`
  margin-right: 10px;
  color: #ccc;
`;

export const Breadcrumb = ({ items }) => {
  const location = useLocation();

  return (
    <BreadcrumbContainer>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <BreadcrumbItem
            to={item.link}
            isActive={location.pathname === item.link}
          >
            {item.name}
          </BreadcrumbItem>
          {index < items.length - 1 && <BreadcrumbSeparator>/</BreadcrumbSeparator>}
        </React.Fragment>
      ))}
    </BreadcrumbContainer>
  );
};