import styled from "styled-components";
import logo from "../assets/logo.png";
import { v } from "../styles/Variables";
import {
  AiOutlineLeft,
  AiOutlineHome,
  AiOutlineApartment,
  AiOutlineUser,
  AiOutlineShopping,
} from "react-icons/ai";
import { MdOutlineAnalytics, MdLogout } from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";

export function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const navigate = useNavigate();
  const ModSidebaropen = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const handleLogout = () => {
    // Eliminar los valores del localStorage
    localStorage.removeItem("userId");
    localStorage.removeItem("companyId");
    localStorage.removeItem("storeSubsections");
    localStorage.removeItem("templateCode");
    localStorage.removeItem("dominio");
    localStorage.removeItem("telefono");
    localStorage.removeItem("storeId");
    localStorage.removeItem("descrquiensomos");
    localStorage.removeItem("selectedTemplate");
    localStorage.removeItem("redsocialyt");
    localStorage.removeItem("nombre");
    localStorage.removeItem("authToken");
    localStorage.removeItem("storeColors");
    localStorage.removeItem("descrempresa");
    localStorage.removeItem("redsocialig");
    localStorage.removeItem("redsocialtw");
    localStorage.removeItem("contact");
    localStorage.removeItem("transbank");
    localStorage.removeItem("desctermycond");
    localStorage.removeItem("email");
    localStorage.removeItem("redsocialfb");
    localStorage.removeItem("currentSection");
    localStorage.removeItem("direccion");
    localStorage.removeItem("bankAccount");


    // Redirigir a la página de inicio de sesión
    navigate("/");
};


  return (
    <Container isOpen={sidebarOpen}>
      <button className="Sidebarbutton" onClick={ModSidebaropen}>
        <AiOutlineLeft />
      </button>
      <div className="Logocontent">
        <div className="imgcontent">
          <img src={logo} />
        </div>
      </div>
      {linksArray.map(({ icon, label, to }) => (
        <div className="LinkContainer" key={label}>
          <NavLink
            to={to}
            className={({ isActive }) => `Links${isActive ? ` active` : ``}`}
          >
            <div className="Linkicon">{icon}</div>
            {sidebarOpen && <span>{label}</span>}
          </NavLink>
        </div>
      ))}
      <Divider />
      {secondarylinksArray.map(({ icon, label, to }) => (
        <div className="LinkContainer" key={label}>
        <div
          className="Links"
          onClick={handleLogout}
          style={{ cursor: 'pointer' }}
        >
          <div className="Linkicon">{icon}</div>
          {sidebarOpen && <span>{label}</span>}
        </div>
      </div>
      ))}
      <Divider />

    </Container>
  );
}
//#region Data links
const linksArray = [
  {
    label: "Tiendas",
    icon: <AiOutlineShopping />,
    to: "/tienda",
  },
  {
    label: "Usuarios",
    icon: <AiOutlineUser />,
    to: "/usuarios",
  },
  {
    label: "Empresa",
    icon: <AiOutlineApartment />,
    to: "/empresa",
  },
  //{
  //  label: "Planes",
  //  icon: <MdOutlineAnalytics />,
  //  to: "/planes",
  //},
];
const secondarylinksArray = [
  {
    label: "Salir",
    icon: <MdLogout />,
    action: "handleLogout",
  },
];
//#endregion

//#region STYLED COMPONENTS
const Container = styled.div`
  color: "#202020";
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  padding-top: 20px;
  z-index: 2000;
  width: ${({ isOpen }) => (isOpen ? `200px` : `80px`)};
  height: 100vh;
  transition: width 0.3s ease;
  .Sidebarbutton {
    position: absolute;
    top: ${v.xxlSpacing};
    right: -18px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 0 4px rgba(148, 148, 148, 0.301),
      0 0 7px rgba(151, 151, 151, 0.247);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
    transform: ${({ isOpen }) => (isOpen ? `initial` : `rotate(180deg)`)};
    border: none;
    letter-spacing: inherit;
    color: inherit;
    font-size: inherit;
    text-align: inherit;
    padding: 0;
    font-family: inherit;
    outline: none;
  }
  .Logocontent {
    display: flex;
    justify-content: center;
    align-items: center;

    padding-bottom: ${v.lgSpacing};
    .imgcontent {
      display: flex;
      img {
        max-width: 100%;
        height: auto;
      }
      cursor: pointer;
      transition: all 0.3s;
      transform: ${({ isOpen }) => (isOpen ? `scale(0.7)` : `scale(0.8)`)};
    }
    h2 {
      display: ${({ isOpen }) => (isOpen ? `block` : `none`)};
    }
  }
  .LinkContainer {
    margin: 8px 0;
    color: #202020;
    cursor: pointer;
    padding-left: ${({ isOpen }) => (isOpen ? `16px` : `10px`)};
    :hover {
      background-color: #faf4f4;
      color: #f5213d; 
      span {
        color: #f5213d; 
      }
      .Linkicon svg {
        color: #f5213d; 
      }
    }
    .Links {
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: calc(${v.smSpacing}-5px) 0;
      color: #202020;
      height:50px;
      font-size: 15px;
      .Linkicon {
        padding: ${v.smSpacing} ${v.mdSpacing};
        display: flex;

        svg {
          font-size: 22px;
          font-weight: 400;
        }
      }
      
      &.active {
        color: #f5213d;
        .Linkicon {
          svg {
            color: #f5213d;
          }
        }
      }
    }
  }
  .Themecontent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .titletheme {
      display: block;
      padding: 10px;
      font-weight: 700;
      opacity: ${({ isOpen }) => (isOpen ? `1` : `0`)};
      transition: all 0.3s;
      white-space: nowrap;
      overflow: hidden;
    }
    .Togglecontent {
      margin: ${({ isOpen }) => (isOpen ? `auto 40px` : `auto 15px`)};
      width: 36px;
      height: 20px;
      border-radius: 10px;
      transition: all 0.3s;
      position: relative;
      .theme-container {
        background-blend-mode: multiply, multiply;
        transition: 0.4s;
        .grid {
          display: grid;
          justify-items: center;
          align-content: center;
          height: 100vh;
          width: 100vw;
          font-family: "Lato", sans-serif;
        }
        .demo {
          font-size: 32px;
          .switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 34px;
            .theme-swither {
              opacity: 0;
              width: 0;
              height: 0;
              &:checked + .slider:before {
                left: 4px;
                content: "🌑";
                transform: translateX(26px);
              }
            }
            .slider {
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: ${({ themeUse }) =>
                themeUse === "light" ? v.lightcheckbox : v.checkbox};

              transition: 0.4s;
              &::before {
                position: absolute;
                content: "☀️";
                height: 0px;
                width: 0px;
                left: -10px;
                top: 16px;
                line-height: 0px;
                transition: 0.4s;
              }
              &.round {
                border-radius: 34px;

                &::before {
                  border-radius: 50%;
                }
              }
            }
          }
        }
      }
    }
  }
`;
const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${(props) => props.theme.bg3};
  margin: ${v.lgSpacing} 0;
`;
//#endregion
