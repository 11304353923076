import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import logo from '../../assets/logo.png';
import { FiSearch } from "react-icons/fi";

// Estilos generales de sección
const Section = styled.section`
    padding: 50px 0;
    background-color: ${props => props.bgColor || '#fff'};
    text-align: center;
`;

// Estilos compartidos para títulos de secciones
const SectionTitle = styled.h2`
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px;
`;

// Estilos compartidos para elementos de lista de contenido (servicios, testimonios, etc.)
const SectionList = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    flex-wrap: wrap;
`;

const ListItem = styled.div`
    width: 30%;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    border-radius: 5px;
    @media (max-width: 768px) {
        width: 80%;
    }
`;

// Estilos específicos para el banner
const Banner = styled.div`
    position: relative;
    height: 600px;
    background-image: url('https://www.enlacemercado.cl/assets/images/home-banner-1.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: start;
    align-items: center;
    color: white;
    font-family: 'Roboto', sans-serif;
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
`;

const BannerContent = styled.div`
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-left: 5%;
    margin-top: 50px;
    width: 50%;
    @media (max-width: 768px) {
        width: 90%;
    }
`;

const BannerTitle = styled.h1`
    font-size: 48px;
    font-weight: 600;
`;

const BannerSubtitle = styled.p`
    font-size: 16px;
    margin: 0;
    margin-bottom: 20px;
`;

const BannerButton = styled.button`
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #f5213d;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 12px;
    text-transform: uppercase;
    transition: background-color 0.3s;
    &:hover {
        background-color: #000;
    }
`;

// Estilos para el formulario de contacto
const ContactForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ContactInput = styled.input`
    width: 50%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
    @media (max-width: 768px) {
        width: 80%;
    }
`;

const ContactTextArea = styled.textarea`
    width: 50%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
    height: 100px;
    @media (max-width: 768px) {
        width: 80%;
    }
`;

const Button = styled.button`
    padding: 10px 20px;
    background-color: #f5213d;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 12px;
    text-transform: uppercase;
    transition: background-color 0.3s;
    &:hover {
        background-color: #000;
    }
`;

// Sección de dos columnas
const TwoColumnSection = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 50px;
  background-color: #fff;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
  }
`;

// Columna izquierda (Imagen)
const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 90%;
    border-radius: 10px;
  }
`;

// Columna derecha (Texto e información)
const RightColumn = styled.div`
  flex: 1.2;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    padding-left: 0;
    text-align: center;
  }
`;

// Estilos para el título principal
const Title = styled.h1`
  font-size: 36px;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
`;

// Estilos para el subtítulo
const Subtitle = styled.p`
  font-size: 18px;
  color: #555;
  margin-bottom: 20px;
`;

// Estilos para la lista de estadísticas
const StatsList = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 768px) {
    justify-content: center;
    flex-direction: column;
  }
`;

// Estilos para cada elemento de estadísticas
const StatItem = styled.div`
  margin-right: 40px;
  text-align: center;

  h3 {
    font-size: 24px;
    color: #f5213d;
  }

  p {
    font-size: 14px;
    color: #555;
  }
`;

// Componente principal
export const MainContent = () => {
    return (
        <>
            <Banner>
                <Overlay />
                <BannerContent>
                    <BannerTitle>Transforma tu negocio</BannerTitle>
                    <BannerSubtitle>
                        Crea, gestiona y expande tu tienda en línea con nuestras soluciones integrales de e-commerce
                    </BannerSubtitle>
                    {/* <BannerButton>Comienza ahora</BannerButton> */}
                </BannerContent>
            </Banner>

            {/* Sección de Servicios */}
            <Section bgColor="#f8f8f8">
                <SectionTitle>¿Qué ofrecemos?</SectionTitle>
                <SectionList>
                    <ListItem>Creación de tiendas en línea</ListItem>
                    <ListItem>Fácil de manejar y actualizar</ListItem>
                    <ListItem>Integración de pagos</ListItem>
                </SectionList>
            </Section>
            {/* Sección de Ayuda */}
            <Section>
                <TwoColumnSection>
                {/* Columna izquierda: Imagen */}
                <LeftColumn>
                    <img
                    src="https://amiif.org/wp-content/uploads/2022/08/un-simple-apreton-de-manos-puede-cambiar-el-rumbo-de-las-enfermedades-infecciosas.jpeg" n
                    alt="Imagen 2"
                    />
                </LeftColumn>

                {/* Columna derecha: Texto e información */}
                <RightColumn>
                <   SectionTitle>¿Cómo te podemos ayudar?</SectionTitle>
                    <Subtitle>
                    Somos una Empresa de Soluciones Digitales Integrales que ofrece una variedad de servicios relacionados con el mundo digital. 
                    Estos servicios incluyen, pero no se limitan a, la creación de sitios web, 
                    diseño gráfico, marketing digital, análisis de datos, inteligencia artificial, licitaciones en línea, y servicios de publicidad en línea.
                    </Subtitle>
                </RightColumn>
                </TwoColumnSection>
            </Section>

            {/* Sección de Testimonios */}
            {/* <Section bgColor="#f8f8f8">
                <SectionTitle>Testimonios de nuestros clientes</SectionTitle>
                <SectionList>
                    <ListItem>
                        "Gracias a esta plataforma, pude expandir mi negocio en línea rápidamente."
                        <p>- Juan Pérez</p>
                    </ListItem>
                    <ListItem>
                        "El servicio al cliente es excepcional. Siempre dispuestos a ayudar."
                        <p>- María Gómez</p>
                    </ListItem>
                </SectionList>
            </Section> */}

        </>
    );
};

export default MainContent;
