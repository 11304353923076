import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom"; // Importar useNavigate
import { FaRegEye } from "react-icons/fa6";
import {
  Container,
  Card,
  TitleContainer,
  Title,
  Title2,
  TableWrapper,
  Table,
  Button,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableCell,
  Breadcrumb,
} from "../../styles/styledComponents";
import { useLocation } from "react-router-dom";
import { getAllOrders } from "./services/orders.services"; // Import the service to get orders
import { ErrorAlert } from "../../components/ErrorAlert";
import { DataTable } from "../../styles/DataTable";
export const OrderDashboard = () => {
  const navigate = useNavigate(); // Inicializar el hook para navegar
  const location = useLocation();

  const { storeId } = location.state || {}; // Extraer storeId del estado de navegación

  const [ordenes, setOrdenes] = useState([]);
  const [loading, setLoading] = useState(true); // Inicializar el estado de carga
  const [error, setError] = useState(null); // Inicializar el estado de error
  const [filterData, setFilterData] = useState({
    startDateTime: "2024-10-10T15:36:37.831Z",
    endDateTime: "2024-10-10T15:36:37.831Z",
  });

  // Fetch orders from the API with filter
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        // Verifica que el storeId no esté vacío
        if (!storeId) {
          throw new Error("No se ha proporcionado un Store ID válido.");
        }

        const orderData = await getAllOrders(filterData, storeId); // Pass filterData to the API call
        if (!orderData || !orderData.orders || orderData.orders.length === 0) {
          setError("No hay órdenes disponibles.");
          setLoading(false);
          return;
        }

        setOrdenes(orderData.orders); // Assuming the JSON has a `orders` array
        setLoading(false);
      } catch (error) {
        console.error("Error fetching orders:", error);
        setError(error.message); // Almacena el mensaje de error en el estado para que el componente ErrorAlert lo procese
        setLoading(false); // Asegúrate de cambiar el estado de loading
      }
    };

    fetchOrders();
  }, [filterData, storeId]); // The effect will run again if filterData or storeId changes

  if (loading) return <div>Cargando...</div>; // Render loading state

  const calcularTotalOrden = (products) => {
    return products.reduce((acc, product) => acc + product.total, 0);
  };
  const columns = [
    { accessor: "index", label: "#", width: "5%" },
    { accessor: "creationDateTime", label: "Fecha", width: "15%" },
    { accessor: "buyerDetails", label: "Cliente", width: "25%" },
    { accessor: "total", label: "Total", width: "20%" },
    { accessor: "status", label: "Estado", width: "25%" },
  ];
  
  // Transformar las órdenes para incluir datos necesarios
  const tableData = ordenes.map((order, index) => ({
    order: order,
    index: index + 1,
    creationDateTime: new Date(order.creationDateTime).toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }),
    buyerDetails: `${order.buyerDetails.firstName} ${order.buyerDetails.lastName}`,
    total: `$${(order.totalAmount).toLocaleString('es-ES')}`,
    status: (
      <StatusBadge color={getEstadoColor(order.status)}>
        {getEstadoTexto(order.status)}
      </StatusBadge>
    ),
    id: order.id,
  }));
  console.log('Ordenes:', ordenes);
  return (
    <Container>
      {error && <ErrorAlert error={error} />} {/* Muestra un mensaje de error si hay uno */}

      <TitleContainer>
        <Title>TIENDAS</Title>
        <Breadcrumb
          items={[
            { name: "Inicio", link: "/tienda" },
            { name: "Tiendas", link: "/tienda" },
            { name: "Pedidos", link: "/tienda-pedidos" },
          ]}
        />
      </TitleContainer>
      <Card>
        <Title2>PEDIDOS</Title2>
        <DataTable
              columns={columns}
              data={tableData}
              actions={ [
                {
                  icon: <span>👁️</span>,
                  onClick: (rowData) => navigate("/tienda-verpedido", { state: rowData.order }),
                },
                {
                  icon: <span>✏️</span>,
                  onClick: (rowData) => navigate("/tienda-editpedidos", { state: rowData.order }),
                },
              ]}
            />
      </Card>
    </Container>
  );
};

// Estilos
const TableBody = styled.tbody``;

const NoOrdersMessage = styled.div`
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #888;
`;

const StatusBadge = styled.span`
  padding: 5px 10px;
  color: white;
  background-color: ${(props) => props.color};
  border-radius: 12px;
  font-size: 12px;
`;

const ActionButtonGroup = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

const ActionButton = styled.button`
  background-color: #f0f0f0;
  border: none;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
`;

const getEstadoColor = (status) => {
  switch (status) {
    case "CANCELLED":
      return "red";
    case "PENDING_PAYMENT":
      return "grey";
    case "IN_PREPARATION":
      return "orange";
    case "DELIVERED":
      return "blue";
    case "IN_DISTRIBUTION":
      return "cadetblue";
    case "PAID":
      return "green";
    default:
      return "gray";
  }
};

const getEstadoTexto = (status) => {
  switch (status) {
    case "CANCELLED":
      return "Cancelado";
    case "PENDING_PAYMENT":
      return "Pendiente de pago";
    case "IN_PREPARATION":
      return "En preparación";
    case "DELIVERED":
      return "Entregado";
    case "IN_DISTRIBUTION":
      return "En Reparto";
    case "PAID":
      return "Pagado";
    default:
      return status;
  }
};

export default OrderDashboard;
