import React, { useState } from "react";
import styled from "styled-components";
import logo from '../../assets/logo.png';
import Main from './MainContent'; // Importa el componente LoginPage
import LoginPage from './LoginPage'; // Importa el componente LoginPage
import { Footer } from "../../components/Footer";



// Estilos
const PageContainer = styled.div`
    display: flex;
    width: 100vw;
    flex-direction: column;
    min-height: 100vh; // Para cubrir toda la pantalla
    font-family: 'Roboto', sans-serif;
    right: 0;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    background-color: #000000c0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1002;
    border-radius: 0 0 3px 3px;
    margin: 0 auto;
    left: 0;
    right: 0; 
`;

const Logo = styled.div`
    padding: 10px;
    .imgcontent {
        display: flex;
        justify-content: center;
        img {
            height: 100%;
        } 
        height: 50px;
        margin: -10px
    }
`;


const IntranetButton = styled.button`
    background-color: transparent;
    font-family: 'Roboto', sans-serif;
    color: white;
    padding: 10px 20px;
    border: 1px solid #ffffff6f;
    border-radius: 3px;
    font-size: 12px;
    cursor: pointer;
    text-transform: uppercase;
    margin-right: 50px;
    transition: background-color 0.3s, color 0.3s;
    height: min-content;
    &:hover {
        background-color: #f5213d;
        border: 1px solid #f5213d;
        color: #000;
    }
`;

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1005;
`;

const ModalContent = styled.div`
    background-color: transparent;
    padding-bottom: 60px;
    padding-right: 60px;
    z-index: 1007;
    width: 60vh;
    /* height: 40vh; */
    @media (max-width: 480px) {
        width: 100%;
    }
`;


// Estilos para las secciones
const SectionContainer = styled.div`
    padding: 40px 20px;
    background-color: #f9f9f9;
    text-align: center;
`;

const Title = styled.h3`
    margin-top:100px;
    font-size: 2em;
    color: #333;
`;

const Text = styled.p`
    font-size: 1em;
    color: #555;
    line-height: 1.5;
    margin: 30px 0;
`;
export const WhowearePage = () => {
    const [showLogin, setShowLogin] = useState(false);

    const toggleLogin = () => {
        setShowLogin(!showLogin);
    };

    return (
        <PageContainer>
            <Header>
                <Logo>
                    <div className="imgcontent">
                        <img src={logo} />
                    </div>
                </Logo>
                <IntranetButton onClick={toggleLogin}>Iniciar sesión</IntranetButton>
            </Header>
            <PageContainer>

            <SectionContainer>
            <Title>Quiénes Somos</Title>
            <Text>Somos una Empresa de Soluciones Digitales Integrales que ofrece una variedad de servicios
            relacionados con el mundo digital. Estos servicios incluyen, pero no se limitan a, la creación de
            sitios web, diseño gráfico, marketing digital, análisis de datos, inteligencia artificial, licitaciones
            en línea, y servicios de publicidad en línea. La idea detrás de nuestra empresa es brindar una
            solución única y completa para las necesidades digitales de las empresas, ayudando a los clientes
            a aprovechar al máximo las oportunidades en el mundo digital y mejorar su presencia en línea,
            para esto debemos trabajar juntos para ayudar a las empresas a alcanzar sus objetivos.</Text>

            <Title>Misión</Title>
            <Text>Nuestra misión es proporcionar soluciones tecnológicas innovadoras y de calidad para ayudar a las
            empresas a maximizar su presencia en línea y aumentar su competitividad en el mercado.
            Trabajamos para entender las necesidades únicas de cada cliente y ofrecer soluciones
            personalizadas que se adapten a sus objetivos de negocio. Nuestro equipo altamente capacitado y
            experimentado se esfuerza por brindar servicios excepcionales y soporte continuo para garantizar
            el éxito a largo plazo de nuestros clientes.</Text>

            <Title>Visión</Title>
            <Text>Nuestra visión es ser líderes en el mercado de soluciones digitales integrales, ofreciendo una
            amplia gama de servicios para ayudar a las empresas a alcanzar sus objetivos de negocio y
            aprovechar al máximo las oportunidades en el mundo digital. Nos enorgullecemos de ser una
            empresa innovadora y siempre estamos buscando formas de mejorar y ampliar nuestros servicios.
            Trabajamos mano a mano con nuestros clientes para entender sus necesidades y ofrecer
            soluciones personalizadas que se adapten a sus objetivos de negocio.</Text>
        </SectionContainer>
        </PageContainer>

            <Footer />
            {showLogin && (
                <ModalOverlay onClick={toggleLogin}>
                    <ModalContent onClick={(e) => e.stopPropagation()}>
                        <LoginPage closeModal={toggleLogin} />
                    </ModalContent>
                </ModalOverlay>
            )}
        </PageContainer>
    );
};
