import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Container, CardForm, TitleForm } from "../../styles/styledComponents";  

export const CrearTiendaEtapa7 = () => {
  const [selectedPayments, setSelectedPayments] = useState({
    transferencia: true,
    webpay: false,
    webpayEnlace: false
  });

  const [formData, setFormData] = useState({
    rut: "",
    titular: "",
    banco: "",
    tipoCuenta: "",
    emailTransferencia: "",
    numeroCuenta: "",
    codigoComercio: "",
    rutEmpresa: "",
    razonSocial: "",
    contacto: "",
    emailContacto: "",
    token: "",
  });

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSelectedPayments({
      ...selectedPayments,
      [name]: checked,
    });
  };
  const companyId = localStorage.getItem('companyId');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("companyid:", companyId);

    // Aquí es donde vamos a adaptar la información al JSON
    const adaptedData = {
      storeName: localStorage.getItem("nombre"), // Obtenido en una etapa anterior
      domain: localStorage.getItem("dominio"), // Obtenido en una etapa anterior
      transbank: {      
        commerceCode: formData.codigoComercio,
        identificationNumber: formData.rutEmpresa,
        businessName: formData.razonSocial,
        contact: formData.contacto,
        email: formData.emailContacto,
        key: formData.token,
        enable : selectedPayments.webpay,
      },
      
      bankAccount: selectedPayments.transferencia
        ? {
            identificationNumber: formData.rut,
            accountHolder: formData.titular,
            name: formData.banco,
            type: formData.tipoCuenta,
            email: formData.emailTransferencia,
            number: formData.numeroCuenta,
            enable : selectedPayments.transferencia,
          }
        : null,
      hostWebpay: selectedPayments.webpayEnlace,
      contact: {
        phone: formData.contacto,
        socialNetwork: {
          additionalProp1: localStorage.getItem("redsocialfb"), // Obtenido en una etapa anterior
          additionalProp2: localStorage.getItem("redsocialig"), // Obtenido en una etapa anterior
          additionalProp3: localStorage.getItem("redsocialtw"), // Obtenido en una etapa anterior
        },
        addres: localStorage.getItem("direccion"), // Obtenido en una etapa anterior
        email: formData.emailContacto,
        logo: localStorage.getItem("logo"), // Obtenido en una etapa anterior
        sliderImage1 : localStorage.getItem("sliderImage1"),
        sliderImage2 : localStorage.getItem("sliderImage2"),
        sliderImage3 : localStorage.getItem("sliderImage3"),
      },
    };
  
    // Guardar los datos en localStorage
    localStorage.setItem("bankAccount", JSON.stringify(adaptedData.bankAccount));
    localStorage.setItem("transbank", JSON.stringify(adaptedData.transbank));
    localStorage.setItem("contact", JSON.stringify(adaptedData.contact));
    localStorage.setItem("hostWebpay", JSON.stringify(adaptedData.hostWebpay));
  
    // Aquí puedes enviar el JSON a tu API con Axios u otra librería
    console.log("Adapted JSON: ", JSON.stringify(adaptedData, null, 2));
  

    
    // Luego de enviar la información, navegas a la siguiente etapa
    navigate("/crear-tiendaet8");
  };

  return (
    <Container>
      <CardForm>
            <TitleForm>Ingrese Formas de Pago</TitleForm>

      <form onSubmit={handleSubmit}>
        <FormGroup>
          <CheckboxLabel>
            <Checkbox
              type="checkbox"
              name="transferencia"
              checked={selectedPayments.transferencia}
              onChange={handleCheckboxChange}
            />
            Transferencia Bancaria
          </CheckboxLabel>
        </FormGroup>

        {selectedPayments.transferencia && (
          <>
            <FormGroup>
              <Label>RUT</Label>
              <Input
                type="text"
                name="rut"
                value={formData.rut}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Titular de la cuenta</Label>
              <Input
                type="text"
                name="titular"
                value={formData.titular}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Banco</Label>
              <Input
                type="text"
                name="banco"
                value={formData.banco}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Tipo de cuenta</Label>
              <Input
                type="text"
                name="tipoCuenta"
                value={formData.tipoCuenta}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Email</Label>
              <Input
                type="email"
                name="emailTransferencia"
                value={formData.emailTransferencia}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Número de cuenta</Label>
              <Input
                type="text"
                name="numeroCuenta"
                value={formData.numeroCuenta}
                onChange={handleInputChange}
              />
            </FormGroup>
          </>
        )}

        <FormGroup>
          <CheckboxLabel>
            <Checkbox
              type="checkbox"
              name="webpay"
              checked={selectedPayments.webpay}
              onChange={handleCheckboxChange}
            />
            Pago Webpay
          </CheckboxLabel>
        </FormGroup>

        {selectedPayments.webpay && (
          <>
            <FormGroup>
              <Label>Código de comercio</Label>
              <Input
                type="text"
                name="codigoComercio"
                value={formData.codigoComercio}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>RUT Empresa</Label>
              <Input
                type="text"
                name="rutEmpresa"
                value={formData.rutEmpresa}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Razón social</Label>
              <Input
                type="text"
                name="razonSocial"
                value={formData.razonSocial}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Contacto</Label>
              <Input
                type="text"
                name="contacto"
                value={formData.contacto}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Email contacto</Label>
              <Input
                type="email"
                name="emailContacto"
                value={formData.emailContacto}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Token</Label>
              <Input
                type="text"
                name="token"
                value={formData.token}
                onChange={handleInputChange}
              />
            </FormGroup>
          </>
        )}

        <FormGroup>
          <CheckboxLabel>
            <Checkbox
              type="checkbox"
              name="webpayEnlace"
              checked={selectedPayments.webpayEnlace}
              onChange={handleCheckboxChange}
            />
            Pago Webpay Enlace Mercado
          </CheckboxLabel>
        </FormGroup>

        <SubmitButton type="submit">Continuar</SubmitButton>
      </form>
      </CardForm>
    </Container>
  );
};

// Estilos
const FormContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const CheckboxLabel = styled.label`
  margin-right: 20px;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #555;
  }
`;

export default CrearTiendaEtapa7;
