import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { createCompany } from "../empresa/services/company.services"; // Importar servicio para crear empresa
import { createUser } from "./services/user.services"; // Importar servicio para crear usuario
import logo from '../../assets/logo.png';
import { LoginPage } from "../login/LoginPage";
import { ErrorAlert } from "../../components/ErrorAlert";

export const UserCreate = () => {
  const [showLogin, setShowLogin] = useState(false); // Controla si se muestra el formulario de crear cuenta
  const handleLogin = () => setShowLogin(true);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: "",
    businessName: "",
    phone: "",
    rut: "",
    direccion: "",
    email: "",
    reason: ""

  });

  const [loading, setLoading] = useState(false); // Estado de carga
  const navigate = useNavigate();
  const [error, setError] = useState(null); // Estado para almacenar el error si ocurre

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Estructura de datos para crear empresa
    const companyData = {
      identificationType: "RUT", // Puedes cambiarlo si es necesario
      identificationNumber: formData.rut,
      businessName: formData.businessName,
      address: formData.direccion, // Añade o pide un campo de dirección si es necesario
      email: formData.email,
      phone: formData.phone,
    };

    try {
      // Primero, crear la empresa
      const companyResponse = await createCompany(companyData);

      console.log("datosresponse:", companyResponse); // Para ver si el ID de la empresa se obtiene correctamente

      const companyId = companyResponse.data; // Asegúrate de que este campo contenga el ID de la empresa
      console.log("Company ID:", companyId); // Para ver si el ID de la empresa se obtiene correctamente
      // Luego, crear el usuario con el companyId
      const userData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
        phone: formData.phone,
        reason: formData.reason
      };
      await createUser(userData, companyId);

      navigate("/usuarios");
    } catch (error) {
      console.error("Error al crear la empresa o el usuario:", error);
      setError(error); // Almacena el error en el estado para que el componente ErrorAlert lo procese

    } finally {
      setLoading(false);
    }
  };

  return (
    <FormContainer>
      {error && <ErrorAlert error={error} />}

      {showLogin ? (
        <LoginPage /> // Renderiza el componente de crear cuenta si el usuario hace clic en "Crear cuenta"
      ) : (
      <Form onSubmit={handleSubmit}>
        <div className="imgcontent">
          <img src={logo} />
        </div>
        <Title>Registre un usuario y su empresa para continuar</Title>
        <ContainerForm>
          <UserForm>
            <FormGroup>
              <Input
                type="text"
                placeholder="Nombres"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                placeholder="Apellidos"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="password"
                placeholder="Contraseña"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
          </UserForm>
          <UserForm>
            <FormGroup>
              <Input
                type="text"
                placeholder="Razón Social"
                name="businessName"
                value={formData.businessName}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                placeholder="RUT"
                name="rut"
                value={formData.rut}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                placeholder="Dirección"
                name="direccion"
                value={formData.direccion}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                placeholder="Teléfono"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
          </UserForm>
          
        </ContainerForm>
        <FormGroup>
          <Textarea
            type="reason"
            placeholder="Razon de inscripción"
            name="reason"
            value={formData.reason}
            onChange={handleInputChange}
            required
          />
        </FormGroup>
        <Button type="submit" disabled={loading}>
          {loading ? "Guardando..." : "Confirmar cuenta"}
        </Button>
        <TextLink>¿Ya tiene una cuenta registrada? <Link onClick={handleLogin}>Inicie sesión</Link></TextLink>
      </Form>
      )}
    </FormContainer>
  );
};

const FormContainer = styled.div`
  height: 50vh;
  width: 60vh;
  @media (max-width: 480px) {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  align-items: start;
  font-family: 'Roboto', sans-serif;
  justify-content: center;
  .imgcontent {
    display: flex;
    justify-content: center;
    img {
        height: 100%;
      }
    height: 40px;
    margin-top: 40px;
  }
  
`;

const Form = styled.form`
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  margin: auto;
`;
const ContainerForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px; 
`;

const UserForm = styled.div`
  flex: 0 0 auto;
  width: 50%;
  padding-right: 10px; 
  box-sizing: border-box;
  @media (max-width:480px) {
    width: 100%;
  }
`;

const Title = styled.h2`
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 16px;
`;

const FormGroup = styled.div`
  width: 100%; 
  display: block;
  margin-bottom: 15px;
  padding-right: 5px;
`;

const Label = styled.label`
  display: block;
  width: 100%; 
  margin-bottom: 5px;
  font-size: 14px;
`;

const Input = styled.input`
  padding: 10px;
  background-color: #f8f8f8;
  border: 1px solid #fff;
  border-radius: 3px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box; 
  &:focus {
    border: 1px solid #ccc;
    background-color: transparent;
    outline: none;
  }
`;
const Textarea = styled.textarea`
  padding: 10px;
  font-family: 'Roboto', sans-serif;
  background-color: #f8f8f8;
  border: 1px solid #fff;
  border-radius: 3px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box; 
  &:focus {
    border: 1px solid #ccc;
    background-color: transparent;
    outline: none;
  }
`;
const Button = styled.button`
  padding: 10px 20px;
  background-color: #000;
  font-family: 'Roboto', sans-serif;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    background-color: #333;
  }
`;
const TextLink = styled.div`
  color: #000;
  text-align: center;
  font-size: 14px;
`;
const Link = styled.a`
  color: #000;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  &:hover {
    color: #f5213d;
  }
`;

export default UserCreate;
