import React, { createContext, useState, useContext } from "react";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);

  const login = (authToken) => {
    setToken(authToken); // Guardar el token en el estado
    localStorage.setItem("authToken", authToken); // También guardarlo en localStorage
  };

  return (
    <AuthContext.Provider value={{ token, setToken, login }}>
      {children}
    </AuthContext.Provider>
  );
};
