import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Title3 } from "../../../styles/styledComponents";

export function ShopConfigStart({ storeId, initialData = {} }) {


  return (
    <>
      <Title3>Bienvenido a configuracion de la tienda </Title3>
      <Form>
        <Label>Seleccione una de las opciones para comenzar a modificar</Label>

      </Form>
    </>
  );
}

const Form = styled.form`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 100px;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;


