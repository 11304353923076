import styled from "styled-components";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getCompany, updateCompany } from "./services/company.services"; // Importar servicios
import { ErrorAlert } from "../../components/ErrorAlert";
import { Container, Card, TitleContainer, Title, Title2, Button,
  Form,
  FormRow,
  Select,
  Label,
  Input, Breadcrumb } from "../../styles/styledComponents";


export function CompanyEdit() {
  const [companyData, setCompanyData] = useState({
    razonSocial: "",
    direccion: "",
    correo: "",
    rut: ""
  });
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null); 
  const navigate = useNavigate(); // Para navegar después de la actualización
  const location = useLocation(); // Obtener parámetros de navegación
  const { companyId } = location.state || {}; 

  useEffect(() => {
    const fetchCompany = async () => {
      try {

        const response = await getCompany(companyId); // Obtener datos por ID
        const company = response.data;
        console.log("compañia",company)
        setCompanyData(company); // Prellenar formulario con los datos
      } catch (err) {
        setError("Error al obtener la empresa");
      } finally {
        setLoading(false);
      }
    };

    fetchCompany(); 
  }, [companyId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyData({
      ...companyData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const companyId = localStorage.getItem("companyId");
      console.log("datoscompany",companyData)
      await updateCompany(companyId, companyData); // Enviar datos actualizados
      navigate("/empresa"); // Redirigir a otra página si se desea
    } catch (err) {
      setError("Error al actualizar la empresa");
    }
  };

  if (loading) return <p>Cargando...</p>; // Mostrar mensaje de carga
  if (error) return <p>{error}</p>; // Mostrar errores si existen

  return (
    <Container>

      {error && <ErrorAlert error={error} />}

      {/* <BackButton onClick={() => navigate(-1)}>←</BackButton> */}
      <TitleContainer>
        <Title>Empresa</Title>
          <Breadcrumb items={[
            { name: "Inicio", link: "/tienda" },
            { name: "Empresa", link: "/empresa" },
            { name: "Actualizar Empresa", link: "/editar-empresa" },
          ]} />
        </TitleContainer>
      <Card>
        <Title2>Actualizar empresa</Title2>
      <Form onSubmit={handleSubmit}>
        <FormRow>
          <Label>Razón Social</Label>
          <Input 
            type="text" 
            name="businessName" 
            value={companyData.businessName} 
            onChange={handleInputChange} 
            required 
            disabled
          />
        </FormRow>

        <FormRow>
          <Label>Dirección</Label>
          <Input 
            type="text" 
            name="address" 
            value={companyData.address} 
            onChange={handleInputChange} 
            required 
          />
        </FormRow>

        <FormRow>
          <Label>Correo</Label>
          <Input 
            type="email" 
            name="email" 
            value={companyData.email} 
            onChange={handleInputChange} 
            required 
          />
        </FormRow>

        <FormRow>
          <Label>RUT</Label>
          <Input 
            type="text" 
            name="identificationNumber" 
            value={companyData.identificationNumber} 
            onChange={handleInputChange} 
            disabled
            required 
          />
        </FormRow>

        <Button type="submit">Guardar información compañía</Button>
      </Form>
      </Card>
    </Container>
  );
}

// Estilos

const FormContainer = styled.form`
  margin-top: 20px;
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

