import React from "react";
import { Container, Title, Title2 } from "../../styles/styledComponents";

const Error = () => {
  return (
    <Container>
        <Title>ERROR 404</Title>
        <Title2>Página no encontrada</Title2>
        <p>Lo sentimos, la página que estás buscando no existe.</p>
    </Container>
  );
};

export default Error;
