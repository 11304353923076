import axios from 'axios';

const API_URL = process.env.REACT_APP_COMPANYMANAGEMENT_API_URL;

// Servicios para Company
export const getInfouser = async (userData) => {
  try {
    // Espera a que la promesa se resuelva
    const response = await axios.post(`${API_URL}/Login`, userData);

    console.log("Respuesta completa:", response); // Verifica toda la respuesta

    return response.data; // Devuelve solo los datos necesarios
  } catch (error) {
    console.error("Error en la solicitud:", error);
    throw error; // Re-lanza el error para que pueda ser manejado en el componente
  }
};
