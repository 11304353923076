import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { createProduct, getProduct } from "./services/products.services"; // Import the services
import { ErrorAlert } from "../../components/ErrorAlert";
import {
  Container,
  Card,
  TitleContainer,
  Title,
  Title2,
  Button,
  Form,
  FormRow,
  Label,
  Input,
  TextArea,
  Breadcrumb 
} from "../../styles/styledComponents";

export const ProductsDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { storeId, productId } = location.state || {}; // Extract storeId and productId from navigation state
  const [error, setError] = useState(null); // Estado para almacenar el error si ocurre

  const [formData, setFormData] = useState({
    name: "",
    quantity: 1,
    description: "",
    prices: {
      normalPrice: "",
      merchant: {
        percentage: 0,
        miniumQuantity: 0
      },
      wholesaler: {
        percentage: 0,
        miniumQuantity: 0
      },
      distributors: {
        percentage: 0,
        miniumQuantity: 0
      }
    },
    offer: {
      enable: false,
      percentage: 0,
    },
    category: {
      mainCategory: "",
      subCategory: "",
    },
    attachments: {
      images: ["", "","", "",""],
    },
  });

  useEffect(() => {
    const fetchProduct = async () => {
      if (productId) {
        try {
          console.log("productId:", productId);
          console.log("storeId:", storeId);
  
          const productData = await getProduct(productId, storeId);
  
          console.log("Product data fetched:", productData); // Agrega este log para ver la respuesta
  
          if (productData && productData.product) {
            setFormData(productData.product); // Asegúrate de que los datos estén disponibles aquí
          } else {
            console.error("Error: No se recibió el producto en la respuesta.");
          }
        } catch (error) {
          console.log("Error: ", error);
          setError(error); 
        }
      }
    };
    fetchProduct();
  }, [productId, storeId]);

  return (
    <Container>
      {error && <ErrorAlert error={error} />}

      <TitleContainer>
        <Title>PRODUCTOS</Title>
        <Breadcrumb 
        items={[
          { name: "Inicio", link: "/tienda" },
          { name: "Tiendas", link: "/tienda" },
          { name: "Detalle", link: "/" },
        ]}
      />
      </TitleContainer>      
      <Card>
        <Title2>Ver Producto</Title2>
        <Form>
                {/* Product Name */}
                <FormRow>
                  <Label>Nombre del Producto</Label>
                    <Input
                      type="text"
                      name="name"
                      value={formData.name}
                      required
                      disable
                    />
                </FormRow>
                {/* Quantity */}
                <FormRow>
                  <Label>Cantidad</Label>
                    <Input
                      type="number"
                      name="quantity"
                      value={formData.quantity}
                      disable
                    />
                </FormRow>
                {/* Description */}
                <FormRow>
                  <Label>Descripción</Label>
                    <TextArea
                      name="description"
                      value={formData.description}
                      disable
                    />
                </FormRow>

                {/* Prices 
                <FormRow>
                  <Label>Precio Bruto</Label>
                    <Input
                      type="text"
                      name="grossPrice"
                      value={formData.prices.grossPrice}
                      disable
                    />
                </FormRow>*/}
                <FormRow>
                  <Label>Precio Normal</Label>
                    <Input
                      type="text"
                      name="normalPrice"
                      value={formData.prices.normalPrice}
                      disable
                    />
                </FormRow>
                {/* WholeSale Product */}
                <FormRow>
                  <Label>Precio Comercio</Label>
                    <Input
                      type="text"
                      value={formData.prices.merchant.percentage}
                      disabled
                    />
                </FormRow>
                <FormRow>
                  <Label>Cantidad Mínima Comercio</Label>
                    <Input
                      type="number"
                      value={formData.prices.merchant.miniumQuantity}
                      disabled
                    />
                </FormRow>
                <FormRow>
                  <Label>Precio por Mayor</Label>
                    <Input
                      type="text"
                      value={formData.prices.wholesaler.percentage}
                      disabled
                    />
                </FormRow>
                <FormRow>
                  <Label>Cantidad Mínima al por Mayor</Label>
                    <Input
                      type="number"
                      value={formData.prices.wholesaler.miniumQuantity}
                      disabled
                    />
                </FormRow>
                <FormRow>
                  <Label>Precio Distribuidor</Label>
                    <Input
                      type="text"
                      value={formData.prices.distributors.percentage}
                      disabled
                    />
                </FormRow>
                <FormRow>
                  <Label>Cantidad Mínima Distribuidor</Label>
                    <Input
                      type="number"
                      value={formData.prices.distributors.miniumQuantity}
                      disabled
                    />
                </FormRow>
                {/* Offer */}
                <FormRow>
                  <Label>Habilitar Oferta</Label>
                    <Input
                      type="checkbox"
                      name="enable"
                      checked={formData.offer.enable}
                      disable
                    />
                </FormRow>
                <FormRow>
                  <Label>Porcentaje de Oferta</Label>
                    <Input
                      type="number"
                      name="percentage"
                      value={formData.offer.percentage}
                      disabled={!formData.offer.enable}
                      disable
                    />
                </FormRow>
                {/* Category */}
                <FormRow>
                  <Label>Categoría Principal</Label>
                    <Input
                      type="text"
                      name="mainCategory"
                      value={formData.category.mainCategory}
                      disable
                    />
                </FormRow>
                <FormRow>
                  <Label>Subcategoría</Label>
                    <Input
                      type="text"
                      name="subCategory"
                      value={formData.category.subCategory}
                      disable
                    />
                </FormRow>
                {/* Images */}
                <FormRow>
                  <Label>Imagen 1</Label>
                    <Input
                      type="text"
                      name="image1"
                      value={formData.attachments.images[0]}
                      disable
                    />
                </FormRow>
                <FormRow>
                  <Label>Imagen 2</Label>
                    <Input
                      type="text"
                      name="image2"
                      value={formData.attachments.images[1]}
                      disable
                    />
                </FormRow>
                <FormRow>
                  <Label>Imagen 3</Label>
                    <Input
                      type="text"
                      name="image3"
                      value={formData.attachments.images[2]}
                      disable
                    />
                </FormRow>
                <FormRow>
                  <Label>Imagen 4</Label>
                    <Input
                      type="text"
                      name="image4"
                      value={formData.attachments.images[3]}
                      disable
                    />
                </FormRow>
                <FormRow>
                  <Label>Imagen 5</Label>
                    <Input
                      type="text"
                      name="image5"
                      value={formData.attachments.images[4]}
                      disable
                    />
                </FormRow>
        </Form>
      </Card>
    </Container>
  );
};

export default ProductsDetail;
