import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import {
  Container,
  Card,
  TitleContainer,
  Title,
  Title2,
  Button,
  Form,
  FormRow,
  Label,
  Input,
  Breadcrumb
} from "../../styles/styledComponents";
import { getStore, updateStore } from "./services/shop.service";

export function ShopInfoEdit() {
  const [currentComponent, setCurrentComponent] = useState(null); // Componente inicial
  const [storeData, setStoreData] = useState({
    storeName: "",
    domain: ""
  }); // Datos de la tienda
  const location = useLocation();
  const { storeId } = location.state || {}; // Obtener storeId desde la navegación

  useEffect(() => {
    const fetchStoreData = async () => {
      try {
        const response = await getStore(storeId); // Llamar a la API para obtener los datos de la tienda
        setStoreData({
          storeName: response.data.store.storeName,
          domain: response.data.store.domain
        }); // Guardar los datos en el estado
      } catch (error) {
        console.error("Error al obtener los datos de la tienda:", error);
      }
    };
    if (storeId) fetchStoreData(); // Solo hacer la solicitud si hay storeId
  }, [storeId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStoreData({ ...storeData, [name]: value });
  };

  const handleUpdateStore = async () => {
    try {
      const updateInformationStore = {
        storeName: storeData.storeName,
        domain: storeData.domain
      };

      const response = await updateStore(storeId, updateInformationStore);

      if (response.storeId === storeId) {
        alert("¡Datos actualizados correctamente!");
      }
    } catch (error) {
      alert("Hubo un error al actualizar los datos");
      console.error(error);
    }
  };

  return (
    <Container>
      <TitleContainer>
        <Title>TIENDAS</Title>
        <Breadcrumb items={[
          { name: "Inicio", link: "#" },
          { name: "Tiendas", link: "/tienda" },
          { name: "Actualizar información Tienda", link: "/editar-info-tienda" }
        ]} />
      </TitleContainer> 
      <Card>
        <Title2>Actualizar información Tienda</Title2>
        <Layout>
          <MainSection>
            <MainContent>
              <Form>
              <FormRow>
                <Label>
                  Nombre de la Tienda</Label>
                  <Input
                    type="text"
                    name="storeName"
                    value={storeData.storeName}
                    onChange={handleInputChange}
                  />
              </FormRow>
              <FormRow>
                <Label>
                  Dominio</Label>
                  <Input
                    type="text"
                    name="domain"
                    value={storeData.domain}
                    onChange={handleInputChange}
                  />
                  </FormRow>
                  
              </Form>
            </MainContent>
            <Button
              style={{ width: "150px", alignContent: "center", margin: "auto" }}
              onClick={handleUpdateStore}
            >
              Guardar Cambios
            </Button>
          </MainSection>
        </Layout>
      </Card>
    </Container>
  );
}

// Estilos
const Layout = styled.div`
  display: flex;
  height: 100%; /* Asegura que use todo el alto disponible */
`;

const MainSection = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column; /* Apila MainContent y Button verticalmente */
  justify-content: space-between; /* Asegura que el botón quede al final */
`;

const MainContent = styled.div`
  flex-grow: 1; /* Hace que el contenido principal ocupe el espacio disponible */
`;
