import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import {
  Container,
  Card,
  TitleContainer,
  Title,
  Title2,
  Button,
  Breadcrumb
} from "../../styles/styledComponents";
import { ShopContact } from "./edits/ShopContact";
import { ShopAbout } from "./edits/ShopAbout";
import { ShopConfigSlider } from "./edits/ShopConfigSlider";
import { ShopPay } from "./edits/ShopPay";
import { ShopShipping } from "./edits/ShopShipping";
import { getStore, updateStore, updateWebStore } from "./services/shop.service";
import { ShopConfigStart } from "./edits/ShopConfigStart";

export function ShopEdit() {
  const [currentComponent, setCurrentComponent] = useState(<ShopConfigStart />);
  const [storeData, setStoreData] = useState(null);
  const location = useLocation();
  const { storeId } = location.state || {};

  useEffect(() => {
    const fetchStoreData = async () => {
      try {
        const response = await getStore(storeId);
        setStoreData(response.data);
        setCurrentComponent(<ShopConfigStart />);
      } catch (error) {
        console.error("Error al obtener los datos de la tienda:", error);
      }
    };
    if (storeId) fetchStoreData();
  }, [storeId]);

  const handleNavigation = (Component) => {
    setCurrentComponent(
      <Component
        storeId={storeId}
        initialData={storeData?.store}
        onStoreDataUpdate={handleStoreDataUpdate}
      />
    );
  };

  const handleUpdateStore = async () => {
    const updateInformationStore = {
      storeName: storeData.store.storeName,
      domain: storeData.store.domain,
      transBank: storeData.store.transBank,
      bankAccount: storeData.store.bankAccount,
      contact: storeData.store.contact,
      shippingDetails: storeData.store.shippingDetails,
      pickUpDetails: storeData.store.pickUpDetails,
      description: {
        whoWeAre: storeData.store.descriptionWhoWeAre,
        mision: storeData.store.descriptionMision,
        vision: storeData.store.descriptionVision
      },
      hostWebpay: storeData.store.hostWebpay,

    };

    const response = await updateStore(storeId, updateInformationStore);
    if (response) {
      const formData = new FormData();
      formData.append("storeId", storeId);
  
      if (storeData.store.sliderFiles && storeData.store.sliderFiles.length > 0) {
        storeData.store.sliderFiles.forEach((file) => {
          formData.append("imagesFiles", file);
        });
      } else {
        formData.append("imagesFiles", null);
      }
  
      if (storeData.store.logo) {
        formData.append("logo", storeData.store.logo);
      }
  
      formData.append("storeName", storeData.store.storeName || "");
      formData.append("descriptionWhoWeAre", storeData.store.descriptionWhoWeAre || "");
      formData.append("descriptionMission", storeData.store.descriptionMision || "");
      formData.append("descriptionVision", storeData.store.descriptionVision || "");
      formData.append("primaryColor", storeData.store.primary || "");
      formData.append("secondaryColor", storeData.store.secondary || "");
      formData.append("tertiaryColor", storeData.store.tertiary || "");
  
      const responseWebStore = await updateWebStore(storeId, formData);
      console.log("ResponseWebStore", responseWebStore);
    } else {
      console.log("Error al actualizar la tienda");
    }
  };

  const handleStoreDataUpdate = (updatedData) => {
    setStoreData((prevData) => ({
      ...prevData,
      store: {
        ...prevData.store,
        ...updatedData,
      },
    }));
  };

  return (
    <Container>
      <TitleContainer>
        <Title>TIENDAS</Title>
        <Breadcrumb items={[
          { name: "Inicio", link: "/tienda" },
          { name: "Tiendas", link: "/tienda" },
          { name: "Configuración Tienda", link: "/editar-tienda" }
        ]} />
      </TitleContainer>
      <Card>
        <Title2>Configuración Tienda Web</Title2>
        <Layout>
          <Sidebar>
            <NavButton onClick={() => handleNavigation(ShopContact)}>Información de Contacto</NavButton>
            <NavButton onClick={() => handleNavigation(ShopAbout)}>Acerca de la Tienda</NavButton>
            <NavButton onClick={() => handleNavigation(ShopConfigSlider)}>Imágenes y Colores</NavButton>
            <NavButton onClick={() => handleNavigation(ShopPay)}>Método de pago</NavButton>
            <NavButton onClick={() => handleNavigation(ShopShipping)}>Método de envios</NavButton>
          </Sidebar>
          <MainSection>
            <MainContent>{currentComponent}</MainContent>
            {currentComponent.type !== ShopConfigStart && (
              <Button style={{ width: "150px", alignContent: "center", margin: "auto" }} onClick={handleUpdateStore}>
                Guardar Cambios
              </Button>
            )}
          </MainSection>
        </Layout>
      </Card>
    </Container>
  );
}

// Estilos
const Layout = styled.div`
  display: flex;
  height: 100%;
`;

const Sidebar = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 20px;
`;

const MainSection = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
`;

const MainContent = styled.div`
  flex-grow: 1;
  padding: 20px;
  border-radius: 10px;
`;

const NavButton = styled.button`
  background-color: transparent;
  text-align: left;
  cursor: pointer;
  border: 0;

  &:hover {
    color: #333333;
  }
`;
