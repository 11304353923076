import React, { useState } from "react";
import styled from "styled-components";
import logo from '../../assets/logo.png';
import Main from './MainContent'; // Importa el componente LoginPage
import LoginPage from './LoginPage'; // Importa el componente LoginPage
import { Footer } from "../../components/Footer";

const TermsContainer = styled.div`
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Roboto', sans-serif;
`;

const Title = styled.h3`
    margin-top: 110px;
    color: #e95b5b;
    text-align: center;
`;

const Section = styled.div`
    text-align: justify;
    color: #333;
    line-height: 1.6;
    font-size: 16px;
    margin-top: 50px;
`;

// Estilos
const PageContainer = styled.div`
    display: flex;
    width: 100vw;
    flex-direction: column;
    min-height: 100vh; // Para cubrir toda la pantalla
    font-family: 'Roboto', sans-serif;
    right: 0;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    background-color: #000000c0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1002;
    border-radius: 0 0 3px 3px;
    margin: 0 auto;
    left: 0;
    right: 0; 
`;
const Nav = styled.nav`
    gap: 20px; // Espacio entre los items
    padding: 10px;
`;
const Logo = styled.div`
    padding: 10px;
    .imgcontent {
        display: flex;
        justify-content: center;
        img {
            height: 100%;
        } 
        height: 50px;
        margin: -10px
    }
`;

const IntranetButton = styled.button`
    background-color: transparent;
    font-family: 'Roboto', sans-serif;
    color: white;
    padding: 10px 20px;
    border: 1px solid #ffffff6f;
    border-radius: 3px;
    font-size: 12px;
    cursor: pointer;
    text-transform: uppercase;
    transition: background-color 0.3s, color 0.3s;
    height: min-content;
    margin-right: 50px;
    &:hover {
        background-color: #f5213d;
        border: 1px solid #f5213d;
        color: #000;
    }
`;

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1005;
`;

const ModalContent = styled.div`
    background-color: transparent;
    padding-bottom: 60px;
    padding-right: 60px;
    z-index: 1007;
    width: 60vh;
    /* height: 40vh; */
    @media (max-width: 480px) {
        width: 100%;
    }
`;
const Form = styled.form`
    display: flex;
    justify-content: center;
    margin-top: 40px;
`;
const Input = styled.input`
  padding: 10px;
  background-color: #f8f8f8;
  border: 0;
  border-radius: 3px 0 0 3px;  
  margin: 0;
  font-size: 14px;
  width: 30%;
  &:focus {
    background-color: transparent;
    outline: none;
  }
`;
const SearchButton = styled.button`
 border: 0;
 border-radius: 0;
 background-color: #5b7ce9;
 padding: 0 10px;
 color: #fff;
 border-radius: 0 3px 3px 0;
 cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    &:hover {
        background-color: #4f72e2;
    }
`;

export const TermsAndConditionsPage = () => {
    const [showLogin, setShowLogin] = useState(false);

    const toggleLogin = () => {
        setShowLogin(!showLogin);
    };

    return (
        <PageContainer>
            <Header>
                <Logo>
                    <div className="imgcontent">
                        <img src={logo} />
                    </div>
                </Logo>
                <IntranetButton onClick={toggleLogin}>Iniciar sesión</IntranetButton>
            </Header>

            <TermsContainer>
                <Title>Términos y condiciones</Title>
                <Section>
                    <b>1. Aceptación de los Términos</b>
                    <p>
                        1.1. Estos términos y condiciones rigen el uso de nuestro sitio web y todos los servicios ofrecidos por Enlace Mercado. Al acceder o utilizar nuestro sitio, aceptas cumplir con estos términos en su totalidad.
                    </p>
                    <p>
                        1.2. Si no estás de acuerdo con alguno de estos términos y condiciones, te pedimos que no utilices nuestro sitio web.
                    </p>
                </Section>

                <Section>
                    <b>2. Descripción de los Servicios</b>
                    <p>
                        2.1. Enlace Mercado es una Empresa Intermediadora de Soluciones Digitales Integrales que ofrece una variedad de servicios relacionados con el mundo digital. Estos servicios incluyen, pero no se limitan a, la creación de sitios web, desarrollo de software, diseño gráfico, marketing digital, análisis de datos, inteligencia artificial, licitaciones en línea y servicios de publicidad en línea.
                    </p>
                    <p>
                        2.2. Nos esforzamos por brindar soluciones personalizadas y de alta calidad para satisfacer las necesidades de nuestros clientes. Sin embargo, la disponibilidad de ciertos servicios puede estar sujeta a cambios y actualizaciones sin previo aviso.
                    </p>
                </Section>

                <Section>
                    <b>3. Propiedad Intelectual</b>
                    <p>
                        3.1. Todo el contenido proporcionado por Enlace Mercado en nuestro sitio web, incluyendo logotipos, marcas comerciales, imágenes, texto y cualquier material relacionado, está protegido por derechos de autor y propiedad intelectual.
                    </p>
                    <p>
                        3.2. Los usuarios no tienen derecho a copiar, modificar, distribuir o utilizar cualquier contenido de Enlace Mercado sin el permiso explícito de la empresa.
                    </p>
                </Section>

                <Section>
                    <b>4. Responsabilidad del Usuario</b>
                    <p>
                        4.1. Al utilizar nuestro sitio web, los usuarios se comprometen a proporcionar información precisa y actualizada al registrarse y utilizar nuestros servicios.
                    </p>
                    <p>
                        4.2. Los usuarios son responsables de mantener la confidencialidad de sus credenciales de acceso y no deben compartir su cuenta con terceros.
                    </p>
                    <p>
                        4.3. Los usuarios deben utilizar nuestros servicios de manera ética y cumplir con todas las leyes y regulaciones aplicables.
                    </p>
                </Section>

                <Section>
                    <b>5. Privacidad y Protección de Datos</b>
                    <p>
                        5.1. La privacidad de los datos de nuestros usuarios es importante para nosotros. Nuestra Política de Privacidad rige la recopilación, uso y divulgación de la información personal de los usuarios. Al utilizar nuestro sitio web, los usuarios aceptan nuestra Política de Privacidad.
                    </p>
                </Section>

                <Section>
                    <b>6. Uso Adecuado del Sitio</b>
                    <p>
                        6.1. Los usuarios se comprometen a utilizar nuestro sitio web de manera adecuada y a no realizar ninguna acción que pueda dañar, sobrecargar o afectar negativamente la funcionalidad de nuestro sitio.
                    </p>
                    <p>
                        6.2. Los usuarios deben abstenerse de realizar actividades ilegales, difamatorias, ofensivas o que infrinjan los derechos de propiedad intelectual de terceros.
                    </p>
                </Section>

                <Section>
                    <b>7. Responsabilidad y Exclusión de Garantías</b>
                    <p>
                        7.1. Enlace Mercado se esfuerza por brindar información precisa y actualizada en nuestro sitio web. Sin embargo, no podemos garantizar la total precisión o integridad de la información proporcionada.
                    </p>
                    <p>
                        7.2. Enlace Mercado no será responsable por ningún daño directo, indirecto, incidental o consecuente que surja del uso o la incapacidad de utilizar nuestro sitio web o servicios.
                    </p>
                </Section>

                <Section>
                    <b>8. Modificaciones a los Términos y Condiciones</b>
                    <p>
                        8.1. Enlace Mercado se reserva el derecho de modificar estos términos y condiciones en cualquier momento. Las actualizaciones entrarán en vigor tan pronto como se publiquen en nuestro sitio web.
                    </p>
                </Section>

                <Section>
                    <b>9. Jurisdicción y Ley Aplicable</b>
                    <p>
                        9.1. Estos términos y condiciones se rigen por las leyes de Chile; cualquier disputa que surja de este acuerdo estará sujeta a la jurisdicción exclusiva de los tribunales de Santiago – RM de Chile.
                    </p>
                </Section>

                <Section>
                    <b>10. Contacto</b>
                    <p>
                        10.1. Si tienes preguntas o inquietudes sobre estos términos y condiciones, puedes contactarnos a través de un correo pulsando aqui! <a href="mailto:consultas@enlacemercado.cl">Generar correo</a>.
                    </p>
                </Section>
            </TermsContainer>
            <Footer />
            {showLogin && (
                <ModalOverlay onClick={toggleLogin}>
                    <ModalContent onClick={(e) => e.stopPropagation()}>
                        <LoginPage closeModal={toggleLogin} />
                    </ModalContent>
                </ModalOverlay>
            )}
        </PageContainer>
    );
};
