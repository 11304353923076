import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { ErrorAlert } from "../../components/ErrorAlert";
import { useNavigate, useLocation } from "react-router-dom";
import { getUsers } from "./services/user.services";
import { Container, Card, TitleContainer, Title, Title2, Button, Breadcrumb } from "../../styles/styledComponents";

export function UsuariosDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = location.state || {};
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    company: {
      identificationNumber: "",
    },
  });

  useEffect(() => {
    const fetchUser = async () => {
      if (userId) {
        try {
          const userData = await getUsers(userId);
          if (userData) {
            setFormData({
              firstName: userData.data.firstName,
              lastName: userData.data.lastName,
              phone: userData.data.phone,
              email: userData.data.email,
              company: {
                identificationNumber: userData.data.company.identificationNumber,
              },
            });
          } else {
            console.error("Error: No se recibió el usuario en la respuesta.");
          }
        } catch (error) {
          console.error("Error fetching user:", error);
          setError(error);
        }
      }
    };
    fetchUser();
  }, [userId]);

  return (
    <Container>

      {error && <ErrorAlert error={error} />}

      {/* <BackButton onClick={() => navigate(-1)}>←</BackButton> */}
      <TitleContainer>
        <Title>Usuarios</Title>
        <Breadcrumb items={[
          { name: "Inicio", link: "/tienda" },
          { name: "Usuarios", link: "/usuarios" },
          { name: "Detalle", link: "/ver-usuario" }
        ]} />
      </TitleContainer>   
      <Card>
        {/* <ButtonContainer> */}
          <Title2>Información de Usuario</Title2>
        {/* </ButtonContainer> */}
        <InfoItem><strong>Nombres: </strong>{formData.firstName}</InfoItem>
        <InfoItem><strong>Apellidos: </strong>{formData.lastName}</InfoItem>
        <InfoItem><strong>Teléfono: </strong>{formData.phone}</InfoItem>
        <InfoItem><strong>Correo: </strong>{formData.email}</InfoItem>
        <InfoItem><strong>Rut de empresa: </strong>{formData.company.identificationNumber}</InfoItem>
      </Card>
    </Container>
  );
}            
// Estilos        
// const Container = styled.div`
//   height: 100vh; 
//   display: flex; 
//   flex-direction: column;
//   align-items: center;
//   padding: 40px;
 
// `;

const ButtonContainer = styled.div`
  display: flex;
  margin-bottom: 40px; /* Ajuste opcional para separar el botón de la información */
`;

// const Button = styled.button`
//   background-color: #add8e6;
//   border: none;
//   border-radius: 10px;
//   padding: 4px 10px;
//   cursor: pointer;
//   font-size: 15px;
//   margin-left: 415px; /* Ajuste opcional para separar el botón de la información */


//   &:hover {
//     background-color: #87cefa;
//   }
// `;

const BackButton = styled.button`
  align-self: flex-start;
  background: none;
  border: none;  
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 20px;
`;

// const Title = styled.h1`
//   margin-bottom: 20px;
// `;

const InfoContainer = styled.div`
  margin-top: 50px; /* Aumenta el margen superior para bajar el cuadro */

  border: 3px solid #ddd;
  border-radius: 5px;
  padding: 30px;
  width: 850px; 
  background-color: #f9f9f9;
  
`;

const InfoTitle = styled.h2`
  margin-bottom: 15px;
`;

const InfoItem = styled.p`
  margin: 5px 0;
  font-size: 16px;
  line-height: 1.5;
`;
