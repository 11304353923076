import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getUsers, updateUser } from "./services/user.services"; // Importa los servicios necesarios
import { ErrorAlert } from "../../components/ErrorAlert";
import {
  Container,
  Card,
  TitleContainer,
  Title,
  Title2,
  Button,
  Form,
  FormGroup,
  FormRow,
  Label,
  Input,
  Breadcrumb
} from "../../styles/styledComponents";

export const UserEdit = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: "",
    company: { identificationNumber: "" },
    phone: "",
    email: "",
  });
  
  const { state } = useLocation();
  const { userId } = state || {};
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      if (userId) {
        try {
          const userData = await getUsers(userId);
          if (userData) {
            setFormData({
              firstName: userData.data.firstName,
              lastName: userData.data.lastName,
              password: "", // Dejar en blanco para no mostrar la contraseña
              company: {
                identificationNumber: userData.data.company.identificationNumber,
              },
              phone: userData.data.phone,
              email: userData.data.email,
            });
          }
        } catch (error) {
          console.error("Error fetching user:", error);
          setError(error);
        }
      }
    };
    fetchUser();
  }, [userId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes(".")) {
      const [parent, child] = name.split(".");
      setFormData((prevData) => ({
        ...prevData,
        [parent]: { ...prevData[parent], [child]: value },
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (userId) {
        await updateUser(userId, formData);
      } else {
        console.log("No se especificó un ID de usuario para actualizar.");
      }
      navigate("/usuarios");
    } catch (error) {
      console.error("Error actualizando el usuario:", error);
      setError(error);
    }
  };

  return (
    <Container>
      {error && <ErrorAlert error={error} />}
      <TitleContainer>
        <Title>Usuario</Title>
        <Breadcrumb 
          items={[
            { name: "Inicio", link: "/tienda" },
            { name: "Usuario", link: "/usuarios" },
            { name: "Actualizar Usuario", link: "/" },
          ]}
        />
      </TitleContainer> 
      <Card>
        <Title2>Actualizar usuario</Title2>
        <Form onSubmit={handleSubmit}>
          <FormRow>
            <Label>Nombres</Label>
            <Input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
            />
          </FormRow>
          <FormRow>
            <Label>Apellidos</Label>
            <Input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
            />
          </FormRow>
          <FormRow>
            <Label>Contraseña</Label>
            <Input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
            />
          </FormRow>
          <FormRow>
            <Label>RUT Empresa</Label>
            <Input
              type="text"
              name="company.identificationNumber"
              value={formData.company.identificationNumber}
              onChange={handleInputChange}
            />
          </FormRow>
          <FormRow>
            <Label>Teléfono</Label>
            <Input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
            />
          </FormRow>
          <FormRow>
            <Label>Email</Label>
            <Input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </FormRow>
          <Button type="submit">Actualizar Información</Button>
        </Form>
      </Card>
    </Container>
  );
};

export default UserEdit;
