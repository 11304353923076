import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Container, CardForm, TitleForm } from "../../styles/styledComponents";  

export const CrearTiendaEtapa8 = () => {
  const [selectedPayments, setSelectedPayments] = useState({
    pickup: true,
    shipping: false
  });

  const [formData, setFormData] = useState({
    price: "",
  });

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSelectedPayments({
      ...selectedPayments,
      [name]: checked,
    });
  };
  const companyId = localStorage.getItem('companyId');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("companyid:", companyId);

    // Aquí es donde vamos a adaptar la información al JSON
    const adaptedData = {
      storeName: localStorage.getItem("nombre"), // Obtenido en una etapa anterior
      domain: localStorage.getItem("dominio"), // Obtenido en una etapa anterior
      shippingDetails: {      
        price: formData.price || 0 ,
        enable : selectedPayments.shipping,
      },
      pickUpDetails: {      
        enable : selectedPayments.pickup,
      },
      contact: {
        phone: formData.contacto,
        socialNetwork: {
          additionalProp1: localStorage.getItem("redsocialfb"), // Obtenido en una etapa anterior
          additionalProp2: localStorage.getItem("redsocialig"), // Obtenido en una etapa anterior
          additionalProp3: localStorage.getItem("redsocialtw"), // Obtenido en una etapa anterior
        },
        addres: localStorage.getItem("direccion"), // Obtenido en una etapa anterior
        email: formData.emailContacto,
        logo: localStorage.getItem("logo"), // Obtenido en una etapa anterior
        sliderImage1 : localStorage.getItem("sliderImage1"),
        sliderImage2 : localStorage.getItem("sliderImage2"),
        sliderImage3 : localStorage.getItem("sliderImage3"),
      },
      description: {
        whoWeAre: localStorage.getItem("descrempresa"),
        mision: localStorage.getItem("descrquiensomos"),
        vision:  localStorage.getItem("desctermycond"),
      }
    };
  
    // Guardar los datos en localStorage
    localStorage.setItem("shippingDetails", JSON.stringify(adaptedData.shippingDetails));
    localStorage.setItem("pickUpDetails", JSON.stringify(adaptedData.pickUpDetails));
    
    // Aquí puedes enviar el JSON a tu API con Axios u otra librería
    console.log("Adapted JSON: ", JSON.stringify(adaptedData, null, 2));
  

    
    // Luego de enviar la información, navegas a la siguiente etapa
    navigate("/crear-tiendaet9");
  };

  return (
    <Container>
      <CardForm>
        <TitleForm>Ingrese métodos de envío</TitleForm>
 
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <CheckboxLabel>
            <Checkbox
              type="checkbox"
              name="pickup"
              checked={selectedPayments.pickup}
              onChange={handleCheckboxChange}
            />
            Retiro en tienda
          </CheckboxLabel>
        </FormGroup>
        <FormGroup>
          <CheckboxLabel>
            <Checkbox
              type="checkbox"
              name="shipping"
              checked={selectedPayments.shipping}
              onChange={handleCheckboxChange}
            />
            Entrega a domicilio
          </CheckboxLabel>
        </FormGroup>

        {selectedPayments.shipping && (
          <>
            <FormGroup>
              <Label>Precio</Label>
              <Input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleInputChange}
              />
            </FormGroup>
          </>
        )}

        <SubmitButton type="submit">Continuar</SubmitButton>
      </form>
      </CardForm>
    </Container>
  );
};

// Estilos
const FormContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const CheckboxLabel = styled.label`
  margin-right: 20px;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #555;
  }
`;

export default CrearTiendaEtapa8;
