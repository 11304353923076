import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Container, CardForm, TitleForm, InfoForm } from "../../styles/styledComponents";  

export function CrearTiendaEtapa2() {
  // Cargar los datos desde localStorage si existen
  const [direccion, setDireccion] = useState(localStorage.getItem("direccion") || "");
  const [telefono, setTelefonos] = useState(localStorage.getItem("telefono") || "");
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [redsocialfb, setRedsocialfb] = useState(localStorage.getItem("redsocialfb") || "");
  const [redsocialig, setRedsocialig] = useState(localStorage.getItem("redsocialig") || "");
  const [redsocialtw, setRedsocialtw] = useState(localStorage.getItem("redsocialtw") || "");

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Guardar la información en localStorage
    localStorage.setItem("direccion", direccion);
    localStorage.setItem("telefono", telefono);
    localStorage.setItem("email", email);
    localStorage.setItem("redsocialfb", redsocialfb);
    localStorage.setItem("redsocialig", redsocialig);
    localStorage.setItem("redsocialtw", redsocialtw);
    
    navigate("/crear-tiendaet3"); // Redirigir a la tercera etapa
  };

  return (
    <Container>
      <CardForm>
          <TitleForm>CREAR TIENDA</TitleForm>
          <InfoForm>Ingrese datos de contacto</InfoForm>
          <Form onSubmit={handleSubmit}>
            <Label>Dirección</Label>
            <Input value={direccion} onChange={(e) => setDireccion(e.target.value)} />
            <Label>Teléfono</Label>
            <Input value={telefono} onChange={(e) => setTelefonos(e.target.value)} />
            <Label>E-mail</Label>
            <Input value={email} onChange={(e) => setEmail(e.target.value)} />
            <Label>Facebook</Label>
            <Input value={redsocialfb} onChange={(e) => setRedsocialfb(e.target.value)} />
            <Label>Instagram</Label>
            <Input value={redsocialig} onChange={(e) => setRedsocialig(e.target.value)} />
            <Label>Twitter</Label>
            <Input value={redsocialtw} onChange={(e) => setRedsocialtw(e.target.value)} />
            <Button type="submit">Continuar</Button>
          </Form>
      </CardForm>
    </Container>
  );
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  
`;

// const Title = styled.h1`
//   margin-bottom: 20px;
// `;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: px;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #000;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #333;
  }
`;
