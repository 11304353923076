import React, { useState, useEffect, useMemo, useRef  } from "react";
import styled from "styled-components";
import { FaRegEye } from "react-icons/fa6";
import { Container, Card } from "./styledComponents";
import { MdArrowDownward, MdArrowUpward, MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

// Estilos adicionales para la paginación y el buscador
const ContainerTableHeader = styled.div`
    display: flex;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    margin-top: 20px;
    input {
        width: 50%;
        padding: 10px;
        background-color: #f8f8f8;
        border: 1px solid #fff;
        border-radius: 3px;
        font-size: 14px;
        font-weight: 400;
        box-sizing: border-box;
        &:focus {
            border: 1px solid #ccc;
            background-color: transparent;
            outline: none;
        }
    }
    label {
        display: flex;
        align-items: center;
        font-family: 'Roboto', sans-serif;
        select {
            padding: 10px;
            border-radius: 3px;
            font-size: 14px;
            font-weight: 400;
            margin-left: 5px;
            background-color: #f8f8f8;
            border: 1px solid #fff;
            font-family: 'Roboto', sans-serif;
            &:focus {
                border: 1px solid #ccc;
                background-color: transparent;
                outline: none;
            }
        }
    }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: start;
  margin-top: 20px;
`;

const PageButton = styled.button`
  padding: 8px 12px;
  margin: 0 4px;
  border: none;
  background-color: ${(props) => (props.active ? "#e6e6e6" : "transparent")};
  color: ${(props) => (props.disabled ? "#9c9c9c" : "#000")};
  border-radius: 3px;
  cursor: ${(props) => (props.disabled ? "" : "pointer")};

  &:hover {
    background-color: ${(props) => (props.disabled ? "transparent" : "#f8f8f8")};
    }
  svg {
    vertical-align: middle;
    padding-bottom: 2.2px;
    text-align: center;
    font-size: 12px;
  }
`;
// Tabla reutilizable
export const TableWrapper = styled.div`
  margin-top: 10px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`;
export const Table = styled.table`
  width: 100%; 
  border-collapse: collapse;
  font-size: 14px;
  font-weight: 400;
`;

export const TableHeader = styled.thead`
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

export const TableHeaderCell = styled.th`
  padding: 12px;
  font-weight: 500;
  text-align: left;
  vertical-align: middle;
  width: 150px;
  &:nth-last-child(1) {
    text-align: right; 
  }
  svg {
    color: #505050;
    vertical-align: middle;
    padding-bottom: 2.2px;
    border: 2px solid transparent;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: #f8f8f8;
        border: 2px solid #f8f8f8;
        border-radius: 5px;
    }
  }
`;
// Acción reutilizable
export const ActionButtonGroup = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

export const ActionButton = styled.button`
  background-color: #f0f0f0;
  border: none;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
`;

export const TableCell = styled.td`
  padding: 12px;
  text-align: left;
  width: 150px;
  &:nth-last-child(1) {
    text-align: right;
  }
`;

// Componente DataTable
export const DataTable = ({ columns, data, actions }) => {
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredData, setFilteredData] = useState(data);
    const [sortConfig, setSortConfig] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    
    useEffect(() => {
        setCurrentPage(1);
    }, [rowsPerPage]);

    useEffect(() => {
        const filtered = data.filter((row) =>
          columns.some((column) =>
            row[column.accessor]?.toString().toLowerCase().includes(search.toLowerCase())
          )
        );
        setFilteredData(filtered);
        setCurrentPage(1); 
    }, [search, data, columns]);
  
    const handleSort = (accessor) => {
      let direction = "ascending";
      if (sortConfig && sortConfig.key === accessor && sortConfig.direction === "ascending") {
        direction = "descending";
      }
      setSortConfig({ key: accessor, direction });
    };
  
    const sortedData = React.useMemo(() => {
      if (sortConfig) {
        return [...filteredData].sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return filteredData;
    }, [filteredData, sortConfig]);
  
    const currentData = sortedData.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );
  
    const totalPages = Math.ceil(filteredData.length / rowsPerPage);
    // Limitar el rango de páginas
    const maxVisiblePages = 4;
    const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    return (
      <>
        <ContainerTableHeader>
            <input
            type="text"
            placeholder="Buscar..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            />
            <label>
                Filas por página:
                <select value={rowsPerPage} onChange={(e) => setRowsPerPage(Number(e.target.value))}>
                {[5, 10, 15, 20].map((option) => (
                    <option key={option} value={option}>
                    {option}
                    </option>
                ))}
                </select>
            </label>
        </ContainerTableHeader>
        <TableWrapper>
          <Table>
            <TableHeader>
              <TableRow>
                {columns.map((column) => (
                  <TableHeaderCell
                    key={column.accessor} style={{ width: column.width }}
                    onClick={() => handleSort(column.accessor)}
                  >
                    {column.label}{" "}
                    {sortConfig?.key === column.accessor && (
                      <span>{sortConfig.direction === "ascending" ? <MdArrowUpward /> : <MdArrowDownward />}</span>
                    )}
                  </TableHeaderCell>
                ))}
                {actions && <TableHeaderCell>Acciones</TableHeaderCell>}
              </TableRow>
            </TableHeader>
            <tbody>
              {currentData.length > 0 ? (
                currentData.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {columns.map((column) => (
                      <TableCell key={column.accessor} style={{ width: column.width }} >
                        {column.render ? column.render(row[column.accessor]) : row[column.accessor]}
                      </TableCell>
                    ))}
                    {actions && (
                      <TableCell>
                        <ActionButtonGroup>
                          {actions.map((action, actionIndex) => (
                            <ActionButton key={actionIndex} onClick={() => action.onClick(row)}>
                              {action.icon}
                            </ActionButton>
                          ))}
                        </ActionButtonGroup>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length + (actions ? 1 : 0)}>No hay resultados</TableCell>
                </TableRow>
              )}
            </tbody>
          </Table>
        </TableWrapper>
  
        <PaginationContainer>
        <PageButton
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
         <MdArrowBackIos />
        </PageButton>
        {Array.from({ length: endPage - startPage + 1 }, (_, i) => (
          <PageButton
            key={startPage + i}
            active={startPage + i === currentPage}
            onClick={() => setCurrentPage(startPage + i)}
          >
            {startPage + i}
          </PageButton>
        ))}
           <PageButton
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          <MdArrowForwardIos />

        </PageButton>
        </PaginationContainer>
      </>
    );
  };
  export const ButtonMore = styled.button`
  font-family: "Roboto", sans-serif;
  background-color: #b9b9b9;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 2px 16px;
  cursor: pointer;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.75;
  text-transform: uppercase;
  min-width: 64px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  &:hover {
    background-color: #acacac;
  }
  &:active {
    transform: scale(0.95); /* Pequeño efecto de "clic" al hacer click */
  }
`;
  const ActionsContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const ActionButtonMore = styled.button`
  background-color: #f0f0f0;
  border: none;
  border-radius: 10px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #e0e0e0;
  }
`;
  export const DataTableTienda = ({ columns, data, actions, expandedRowId, onRowToggle }) => {
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredData, setFilteredData] = useState(data);
    const [sortConfig, setSortConfig] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    useEffect(() => {
        setCurrentPage(1);
    }, [rowsPerPage]);

    useEffect(() => {
        const filtered = data.filter((row) =>
          columns.some((column) =>
            row[column.accessor]?.toString().toLowerCase().includes(search.toLowerCase())
          )
        );
        setFilteredData(filtered);
        if (search !== ""){
          setCurrentPage(1);
        } 
    }, [search, data, columns]);
  
    const handleSort = (accessor) => {
      let direction = "ascending";
      if (sortConfig && sortConfig.key === accessor && sortConfig.direction === "ascending") {
        direction = "descending";
      }
      setSortConfig({ key: accessor, direction });
    };
  
    const sortedData = React.useMemo(() => {
      if (sortConfig) {
        return [...filteredData].sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return filteredData;
    }, [filteredData, sortConfig]);
  
    const currentData = sortedData.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );
  
    const totalPages = Math.ceil(filteredData.length / rowsPerPage);
    // Limitar el rango de páginas
    const maxVisiblePages = 4;
    const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    return (
      <>
        <ContainerTableHeader>
            <input
            type="text"
            placeholder="Buscar..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            />
            <label>
                Filas por página:
                <select value={rowsPerPage} onChange={(e) => setRowsPerPage(Number(e.target.value))}>
                {[5, 10, 15, 20].map((option) => (
                    <option key={option} value={option}>
                    {option}
                    </option>
                ))}
                </select>
            </label>
        </ContainerTableHeader>
        <TableWrapper>
          <Table>
            <TableHeader>
              <TableRow>
                {columns.map((column) => (
                  <TableHeaderCell
                    key={column.accessor}
                    onClick={() => handleSort(column.accessor)}
                  >
                    {column.label}{" "}
                    {sortConfig?.key === column.accessor && (
                      <span>{sortConfig.direction === "ascending" ? <MdArrowUpward /> : <MdArrowDownward />}</span>
                    )}
                  </TableHeaderCell>
                ))}
                {actions && <TableHeaderCell>Acciones</TableHeaderCell>}
              </TableRow>
            </TableHeader>
            <tbody>
              {currentData.length > 0 ? (
                currentData.map((row, rowIndex) => (
                  <React.Fragment key={rowIndex}>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.accessor}>
                          {row[column.accessor]}
                        </TableCell>
                      ))}
                      {actions && (
                        <TableCell>
                          <div>
                            <ButtonMore onClick={() => onRowToggle(row.id)}>
                              {expandedRowId === row.id ? "-" : "+"}
                            </ButtonMore>
                          </div>
                        </TableCell>
                      )}
                    </TableRow>
                    {expandedRowId === row.id && (
                      <TableRow>
                      <TableCell colSpan={columns.length + (actions ? 1 : 0)}>
                        <ActionsContainer>
                          {actions.map((action, actionIndex) => (
                            action.component ? (
                              <div key={actionIndex}>{action.component(row)}</div> 
                            ) : (
                              <ActionButtonMore key={actionIndex} onClick={() => action.onClick(row)}>
                                {action.label}
                              </ActionButtonMore>
                            )
                          ))}
                        </ActionsContainer>
                      </TableCell>
                    </TableRow>
                  )}
                  </React.Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length + (actions ? 1 : 0)}>No hay datos</TableCell>
                </TableRow>
              )}
            </tbody>
          </Table>
        </TableWrapper>
        
        <PaginationContainer>
        <PageButton
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
         <MdArrowBackIos />
        </PageButton>
        {Array.from({ length: endPage - startPage + 1 }, (_, i) => (
          <PageButton
            key={startPage + i}
            active={startPage + i === currentPage}
            onClick={() => setCurrentPage(startPage + i)}
          >
            {startPage + i}
          </PageButton>
        ))}
           <PageButton
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          <MdArrowForwardIos />

        </PageButton>
        </PaginationContainer>
      </>
    );
  };