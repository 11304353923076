// PublishStore.js
import React, { useState } from "react";
import { updateStoreStatus } from "../services/shop.service";
import { ErrorAlert } from "../../../components/ErrorAlert";
import styled from "styled-components";

export const PublishStore = ({ storeId, onSuccess, isActive }) => {
  const [error, setError] = useState(null);

  const handlePublish = async () => {
    try {
      const data = { storeId: storeId, status: "ACTIVATE" }; 
      await updateStoreStatus(storeId, data);
      if (onSuccess) onSuccess(); 
    } catch (error) {
      setError(error);
    }
  };

  const handleNoPublish = async () => {
    try {
      const data = { storeId: storeId, status: "INACTIVATE" }; 
      await updateStoreStatus(storeId, data);
      if (onSuccess) onSuccess(); 
    } catch (error) {
      setError(error);
    }
  };

  return (
    <div>
      {error && <ErrorAlert error={error} />}
      {isActive ? (
        <ActionButton onClick={handleNoPublish}>Desactivar Publicación</ActionButton>
      ) : (
        <ActionButton onClick={handlePublish}>Confirmar Publicación</ActionButton>
      )}
    </div>
  );
};

const ActionButton = styled.button`
  background-color: #f0f0f0;
  border: none;
  border-radius: 10px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #e0e0e0;
  }
`;