// src/components/Navbar.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  background-color: ${({ theme }) => theme === 'light' ? '#fff' : '#333'};
  color: ${({ theme }) => theme === 'light' ? '#333' : '#fff'};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
  right: 0;
  height: 50px;
`;

const Links = styled.div`
  display: flex;
  gap: 1rem;

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Navbar = ({ theme }) => {
  return (
    <NavbarContainer theme={theme}>
      <div></div>
      <Links>
        <Link to="/">Usuario</Link>

      </Links>
    </NavbarContainer>
  );
};

export default Navbar;

