import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  Container,
  CardForm,
  TitleForm,
  InfoForm,
  Form,
  FormRow, 
  Label,
  Input,
  Title3,
  Title2,
} from "../../../styles/styledComponents";
import { updateStore } from "../services/shop.service";

export const ShopPay = ({ storeId, initialData = {} }) => {
  console.log("initialData", initialData);

  const datosTransferencia = initialData?.bankAccount || {};
  const datosTransbank = initialData?.transBank || {};
  const datosHostWebpay = initialData?.hostWebpay || false;

  // Datos Transferencia
  const [emailTransferencia, setEmailTransferencia] = useState(
    datosTransferencia.email || ""
  );
  const [enableTransferencia, setEnableTransferencia] = useState(
    datosTransferencia.enable || false
  );
  const [rutTransferencia, setRutTransferencia] = useState(
    datosTransferencia.identificationNumber || ""
  );
  const [nombreBancoTransferencia, setNombreBancoTransferencia] = useState(
    datosTransferencia.name || ""
  );
  const [numeroCuentaBancoTransferencia, setNumeroCuentaBancoTransferencia] =
    useState(datosTransferencia.number || "");
  const [tipoCuentaBancoTransferencia, setTipoCuentaBancoTransferencia] =
    useState(datosTransferencia.type || "");

  // Datos Transbank
  const [razonSocialTransbank, setRazonSocialTransbank] = useState(
    datosTransbank.businessName || ""
  );
  const [codigoComercioTransbank, setCodigoComercioTransbank] = useState(
    datosTransbank.commerceCode || ""
  );
  const [contactoTransbank, setContactoTransbank] = useState(
    datosTransbank.contact || ""
  );
  const [emailContactoTransbank, setEmailContactoTransbank] = useState(
    datosTransbank.email || ""
  );
  const [enableTransbank, setEnableTransbank] = useState(
    datosTransbank.enable || false
  );
  const [rutTransbank, setRutTransbank] = useState(
    datosTransbank.identificationNumber || ""
  );
  const [tokenTransbank, setTokenTransbank] = useState(
    datosTransbank.token || ""
  );
  // Host Webpay
  const [enableHostWebpay, setEnableHostWebpay] = useState(
    datosHostWebpay || false
  );

  // useEffect para actualizar los datos en initialData
  useEffect(() => {
    if (initialData) {
      initialData.bankAccount = {
        email: emailTransferencia,
        enable: enableTransferencia,
        identificationNumber: rutTransferencia,
        name: nombreBancoTransferencia,
        number: numeroCuentaBancoTransferencia,
        type: tipoCuentaBancoTransferencia,
      };

      initialData.transBank = {
        businessName: razonSocialTransbank,
        commerceCode: codigoComercioTransbank,
        contact: contactoTransbank,
        email: emailContactoTransbank,
        enable: enableTransbank,
        identificationNumber: rutTransbank,
        token: tokenTransbank,
      };
      initialData.hostWebpay = enableHostWebpay;
    }
  }, [
    emailTransferencia,
    enableTransferencia,
    rutTransferencia,
    nombreBancoTransferencia,
    numeroCuentaBancoTransferencia,
    tipoCuentaBancoTransferencia,
    razonSocialTransbank,
    codigoComercioTransbank,
    contactoTransbank,
    emailContactoTransbank,
    enableTransbank,
    rutTransbank,
    tokenTransbank,
    enableHostWebpay,
    initialData,
  ]);
  // Efecto para verificar razonSocialTransbank
  useEffect(() => {
    if (razonSocialTransbank === "EnlaceMercado") {
      setEnableHostWebpay(true);
      setEnableTransbank(false);
      setCodigoComercioTransbank("");
      setContactoTransbank("");
      setEmailContactoTransbank("");
      setRazonSocialTransbank("")
      setRutTransbank("");
      setTokenTransbank("");
    }
  });

  // Control de cambios en enableTransbank y enableHostWebpay
  const handleEnableTransbankChange = (checked) => {
    setEnableTransbank(checked);
    if (checked) setEnableHostWebpay(false);
  };

  const handleEnableHostWebpayChange = (checked) => {
    setEnableHostWebpay(checked);
    if (checked) setEnableTransbank(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Crear objeto con los datos del formulario para el envío
    const data = {
      bankAccount: {
        email: emailTransferencia,
        enable: enableTransferencia,
        identificationNumber: rutTransferencia,
        name: nombreBancoTransferencia,
        number: numeroCuentaBancoTransferencia,
        type: tipoCuentaBancoTransferencia,
      },
      
      transBank: {
        businessName: razonSocialTransbank,
        commerceCode: codigoComercioTransbank,
        contact: contactoTransbank,
        email: emailContactoTransbank,
        enable: enableTransbank,
        identificationNumber: rutTransbank,
        token: tokenTransbank,
      },
      hostWebpay: enableHostWebpay,
    };
    // Puedes manejar el envío de `data` aquí.
  };


  return (
    <>
      <Title3>Formas de Pago</Title3>

      <Form onSubmit={handleSubmit}>
        <FormRow>
          <CheckboxLabel style={{ marginTop: "0"}}>
            <Checkbox
              type="checkbox"
              name="transferencia"
              checked={enableTransferencia}
              onChange={(e) => setEnableTransferencia(e.target.checked)}
            />
            Transferencia Bancaria
          </CheckboxLabel>
        </FormRow>
        <FormRow>
            <Label>Email Transferencia</Label>
            <Input
              type="text"
              name="emailTransferencia"
              value={emailTransferencia}
              onChange={(e) => setEmailTransferencia(e.target.value)}
            />
           </FormRow>
          <FormRow>
            <Label>RUT Transferencia</Label>
            <Input
              type="text"
              name="rutTransferencia"
              value={rutTransferencia}
              onChange={(e) => setRutTransferencia(e.target.value)}
            />
          </FormRow>
          <FormRow>
            <Label>Nombre Banco Transferencia</Label>
            <Input
              type="text"
              name="nombreBancoTransferencia"
              value={nombreBancoTransferencia}
              onChange={(e) => setNombreBancoTransferencia(e.target.value)}
            />
          </FormRow>
          <FormRow>
            <Label>Número Cuenta Banco Transferencia</Label>
            <Input
              type="text"
              name="numeroCuentaBancoTransferencia"
              value={numeroCuentaBancoTransferencia}
              onChange={(e) => setNumeroCuentaBancoTransferencia(e.target.value)}
            />
          </FormRow>
          <FormRow>
            <Label>Tipo Cuenta Banco Transferencia</Label>
            <Input
              type="text"
              name="tipoCuentaBancoTransferencia"
              value={tipoCuentaBancoTransferencia}
              onChange={(e) => setTipoCuentaBancoTransferencia(e.target.value)}
            />
        </FormRow>

        <FormRow>
          <CheckboxLabel>
            <Checkbox
              type="checkbox"
              name="webpay"
              checked={enableTransbank}
              onChange={(e) => handleEnableTransbankChange(e.target.checked)}
            />
            Pago Webpay
          </CheckboxLabel>
        </FormRow>
        <FormRow>
          <Label>Razón Social Transbank</Label>
          <Input
            type="text"
            name="razonSocialTransbank"
            value={razonSocialTransbank}
            onChange={(e) => setRazonSocialTransbank(e.target.value)}
          />
        </FormRow>
        <FormRow>
          <Label>
            Código Comercio Transbank
          </Label>
          <Input
            type="text"
            name="codigoComercioTransbank"
            value={codigoComercioTransbank}
            onChange={(e) => setCodigoComercioTransbank(e.target.value)}
          />
        </FormRow>
        <FormRow>
          <Label>Contacto Transbank</Label>
          <Input
            type="text"
            name="contactoTransbank"
            value={contactoTransbank}
            onChange={(e) => setContactoTransbank(e.target.value)}
          />
        </FormRow>
        <FormRow>
          <Label>
            Email Contacto Transbank
          </Label>
          <Input
            type="email"
            name="emailContactoTransbank"
            value={emailContactoTransbank}
            onChange={(e) => setEmailContactoTransbank(e.target.value)}
          />
        </FormRow>
        <FormRow>
          <Label>RUT Transbank</Label>
          <Input
            type="text"
            name="rutTransbank"
            value={rutTransbank}
            onChange={(e) => setRutTransbank(e.target.value)}
          />
        </FormRow>
        <FormRow>
          <Label>Token Transbank</Label>
          <Input
            type="text"
            name="tokenTransbank"
            value={tokenTransbank}
            onChange={(e) => setTokenTransbank(e.target.value)}
          />
        </FormRow>
        <FormRow>
          <CheckboxLabel>
            <Checkbox
              type="checkbox"
              name="webpayEnlace"
              checked={enableHostWebpay}
              onChange={(e) => handleEnableHostWebpayChange(e.target.checked)}
            />
            Pago Webpay Enlace Mercado
          </CheckboxLabel>
        </FormRow>
      </Form>
    </>
  );
};

// Estilos
const FormContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;


const Checkbox = styled.input`
  margin-right: 10px;
`;

const CheckboxLabel = styled.label`
  margin: 10px 0;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #555;
  }
`;

export default ShopPay;