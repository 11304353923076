import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Container, CardForm, TitleForm, InfoForm, Title3, Form, FormRow, Label, Input } from "../../../styles/styledComponents";
import { updateStore } from "../services/shop.service";

export function ShopContact({ storeId, initialData = {} }) {
  console.log("initialData", initialData);

  const [direccion, setDireccion] = useState(initialData?.contact?.addres || "");
  const [telefono, setTelefonos] = useState(initialData?.contact?.phone || "");
  const [email, setEmail] = useState(initialData?.contact?.email || "");
  const [redsocialfb, setRedsocialfb] = useState(initialData?.contact?.socialNetwork?.additionalProp1 || "");
  const [redsocialig, setRedsocialig] = useState(initialData?.contact?.socialNetwork?.additionalProp2 || "");
  const [redsocialtw, setRedsocialtw] = useState(initialData?.contact?.socialNetwork?.additionalProp3 || "");

  // useEffect para actualizar los datos en initialData.contact cada vez que cambian los valores
  useEffect(() => {
    // Verificar si initialData está definido y asegurarse de que contact también lo esté
    if (initialData && initialData.contact !== undefined) {
      const data = {
        contact: {
          addres: direccion,
          phone: telefono,
          email: email,
          socialNetwork: {
            additionalProp1: redsocialfb,
            additionalProp2: redsocialig,
            additionalProp3: redsocialtw,
          },
        },
      };
      initialData.contact = data.contact;
    }
  }, [direccion, telefono, email, redsocialfb, redsocialig, redsocialtw, initialData]);

  return (
    <>
      <Title3>Ingrese datos de contacto</Title3>
      <Form>
        <FormRow>
          <Label>Dirección</Label>
          <Input value={direccion} onChange={(e) => setDireccion(e.target.value)} />
        </FormRow>
        <FormRow>
          <Label>Teléfono</Label>
          <Input value={telefono} onChange={(e) => setTelefonos(e.target.value)} />
        </FormRow>
        <FormRow>
          <Label>E-mail</Label>
          <Input value={email} onChange={(e) => setEmail(e.target.value)} />
        </FormRow>
          <FormRow>
          <Label>Facebook</Label>
        <Input value={redsocialfb} onChange={(e) => setRedsocialfb(e.target.value)} />
        </FormRow>
        <FormRow>
          <Label>Instagram</Label>
          <Input value={redsocialig} onChange={(e) => setRedsocialig(e.target.value)} />
        </FormRow>
        <FormRow>
          <Label>Twitter</Label>
          <Input value={redsocialtw} onChange={(e) => setRedsocialtw(e.target.value)} />
        </FormRow>
      </Form>
    </>
  );
}

// const Form = styled.form`
//   padding-top: 10px;
//   display: flex;
//   flex-direction: column;
// `;

// const Label = styled.label`
//   margin-bottom: 8px;
// `;

// const Input = styled.input`
//   padding: 10px;
//   margin-bottom: 20px;
//   border: 1px solid #ddd;
//   border-radius: 5px;
// `;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #000;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #333;
  }
`;
