import styled from "styled-components";
import { useState } from "react";
import {
  Container,
  Card,
  TitleContainer,
  Title,
  Title2,
  TableWrapper,
  Table,
  Button,
  TableRow,
  TableCell,
  Breadcrumb
} from "../../styles/styledComponents";


export function Planes() {
  const [planActual, setPlanActual] = useState("Plan 1"); // Estado para el plan actual

  // Función para cambiar de plan
  const mejorarPlan = () => {
    setPlanActual(planActual === "Plan 1" ? "Plan 2" : "Plan 1");
  };

  return (
    <Container>
      <TitleContainer>
        <Title>Planes</Title>
        <Breadcrumb 
        items={[
          { name: "Inicio", link: "/tienda" },
          { name: "Planes", link: "/planes" },
        ]}
      />
      </TitleContainer> 
      <Card>
        <Title2>Su plan actual es:</Title2>
        <CurrentPlan>{planActual}</CurrentPlan>
        <Button onClick={mejorarPlan}>Mejorar plan</Button>
        <h3>Otros planes:</h3>
        <PlanOptions>
          <PlanOption>Plan 2</PlanOption>
          <PlanOption>Plan 3</PlanOption>
        </PlanOptions>
      </Card>
    </Container>
  );
}

// Estilos con styled-components
// const Container = styled.div`
//   height: 100vh;
//   padding: 60px;
  
// `;

const PlanContainer = styled.div`
  background-color: #f4f4f4;
  padding: 100px;
  border-radius: 10px;
  width: 1000px;
  
`;

const CurrentPlan = styled.div`
  font-size: 24px;
  background-color: #e0e0e0;
  padding: 60px;
  margin-bottom: 20px;
  text-align: center;
  border-radius: 5px;
`;

// const Button = styled.button`
//   background-color: #333;
//   color: white;
//   padding: 12px;
//   margin-bottom: 20px;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
// `;

const PlanOptions = styled.div`
  display: flex;
  justify-content: space-around;
  margin-right: -10px;
`;

const PlanOption = styled.div`
  background-color: #d9d9d9;
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  height: 100px
`;

